import {createSlice} from "@reduxjs/toolkit";

export interface SearchInput{
    unprocessedSearchInputs: null | any,
    unprocessedSearchInputsError: boolean,
    loadingUnprocessedSearchInputs: boolean,
}

const initialState:SearchInput = {
    unprocessedSearchInputs: null,
    unprocessedSearchInputsError: false,
    loadingUnprocessedSearchInputs: false
}

export interface inputSlice {
    id: number,
    created: string,
    inputText: string,
    name: string
    username: string,
    phoneNumber: string

//     treba dodati za status
}

const searchInputSlice = createSlice({
    name: "searchInputSlice",
    initialState: initialState,
    reducers: {
        fetchUserSearchInputs: (state, action) => {
            let searchedInputArray: Array<inputSlice> = [];


            action.payload.map((singleInput:any) => {

                let date = new Date(singleInput.created);
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                let minutes = date.getMinutes()<10 ? "0" + date.getMinutes(): date.getMinutes();
                let time = hours + ":" + minutes;

                searchedInputArray.push({
                    id: singleInput.id,
                    created: day + '.' + month + '.' + year + " ( " + time + "h ) ",
                    inputText: singleInput.inputText,
                    name: singleInput.userResponse.firstName + " " + singleInput.userResponse.secondName,
                    username: singleInput.userResponse.username,
                    phoneNumber: singleInput.userResponse.phoneNumber
                })
            })
            state.unprocessedSearchInputs = searchedInputArray;
            state.unprocessedSearchInputsError = false;
            state.loadingUnprocessedSearchInputs = false;
        },
        fetchUserSearchInputsStart: (state) => {
            state.unprocessedSearchInputs = null;
            state.unprocessedSearchInputsError = false;
            state.loadingUnprocessedSearchInputs = true;
        },
        fetchUserSearchInputsFailed: (state) => {
            state.unprocessedSearchInputs = null;
            state.unprocessedSearchInputsError = true;
            state.loadingUnprocessedSearchInputs = false;
        }
    }

});

export const searchInputActions = searchInputSlice.actions;
export default searchInputSlice;