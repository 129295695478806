import React, { useEffect, useRef, useState } from "react";
import styles from "./OrderDetails.module.scss";
import { useDispatch } from "react-redux";
import { ReactComponent as BagIcon } from "./../../../assets/icons/bag-icon.svg";
import { ReactComponent as RFZOIcon } from "./../../../assets/icons/rfzo-icon.svg";
import { ReactComponent as ChatIcon } from "./../../../assets/icons/chat-icon.svg";
import { ReactComponent as CopyIcon } from "./../../../assets/icons/copy-icon.svg";
import {
  fetchPermitsForStore,
  finishOrderAction,
  preparedOrderAction,
  processOrderAction,
} from "../../../store/order-actions";
import { useReactToPrint } from "react-to-print";
import PrintOrderInfo from "../PrintComponents/PrintOrderInfo/PrintOrderInfo";
import PrintOrderDetails from "../../OrderProcessingComponents/PrintComponents/PrintOrderDetails/PrintOrderDetails";
import { orderActions } from "../../../store/order-slice";
import NotificationMessage from "../../UI/NotificationMessage/NotificationMessage";
import ConfirmationModal from "../../UI/Modals/ConfirmationModal/ConfirmationModal";
import ChatBubble from "../../UI/ChatBubble/ChatBubble";
import PrescriptionPhotos from "../PrescriptionPhotos/PrescriptionPhotos";
import Button from "../../UI/Buttons/Button/Button";

type Props = {
  order: any;
  isAdmin: boolean;
  isDriver?: boolean;
  collapseOrder?: Function;
  archived?: boolean;
};
const OrderDetails = ({ order, isAdmin, collapseOrder, archived }: Props) => {
  const dispatch = useDispatch();

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const printOrderInfoRef = useRef<HTMLDivElement>(null);
  const printOrderDetailsRef = useRef<HTMLDivElement>(null);

  const [noteForUser, setNoteForUser] = useState<string>("");
  // const [rejectValidation, setRejectValidation] = useState(false);
  const [notificationMessageVisible, setNotificationMessageVisible] = useState(false);

  const [permitForStore, setPermitForStore] = useState<any>(null);

  const closeModal = () => {
    collapseOrder && collapseOrder();
    dispatch(orderActions.toggleOrderModal(null));
  };

  const closeConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const openConfirmationModal = () => {
    // if (order.status === "CREATED" && noteForUser.length < 3) {
    //   setRejectValidation(true);
    // } else {
    setConfirmationModalVisible(true);
    // }
  };

  const orderPreparedAction = () => {
    dispatch<any>(preparedOrderAction(order.id));
    closeModal();
  };

  const takenOrderAction = () => {
    let nextStatus = "TAKEN";
    if (
      order.deliveryType == "FAST_DELIVERY" ||
      order.deliveryType == "STANDARD_DELIVERY" ||
      order.deliveryType == "PENSION_CARD_DELIVERY"
    ) {
      nextStatus = "IN_DELIVERY";
    }

    dispatch<any>(finishOrderAction(order.id, nextStatus, "noteForUser", order.orderPOSId));
    closeModal();
  };

  const acceptOrder = (willBeReadyIn: number) => {
    dispatch<any>(processOrderAction(order.id, noteForUser, willBeReadyIn));
    closeModal();
  };

  const getNoteForUser = (e: any) => {
    setNoteForUser(e.target.value);
  };

  const printOrderInfoAction = useReactToPrint({
    content: () => printOrderInfoRef.current!,
  });

  const printOrderDetailsAction = useReactToPrint({
    content: () => printOrderDetailsRef.current!,
  });

  const copyLBO = () => {
    navigator.clipboard.writeText(order.userLBO);
    setNotificationMessageVisible(true);
    setTimeout(() => {
      setNotificationMessageVisible(false);
    }, 5000);
  };

  const fetchOrderPermits = () => {
    if (
      order.deliveryType == "FAST_DELIVERY" ||
      order.deliveryType == "STANDARD_DELIVERY" ||
      order.deliveryType == "PENSION_CARD_DELIVERY"
    ) {
      fetchPermitsForStore(order?.userResponse?.id, order?.userDeliveryResponse?.id, order?.userLBO).then((res) => {
        const folderPath = res?.data?.signed ? "user_delivery_permits" : "delivery_permits";
        const fileName = res?.data?.signed ? res?.data?.signedPermitFileName : "p" + res?.data?.id + ".pdf";
        setPermitForStore(process.env.REACT_APP_CDN_PATH + folderPath + "/" + fileName);
      });
    }
    if (order.deliveryType == "PICK_UP_BY_FRIEND") {
      setPermitForStore(process.env.REACT_APP_CDN_PATH + "delivery_permits/o" + order?.id + ".pdf");
    }
  };

  console.log(order);

  useEffect(() => {
    if (order?.userDeliveryResponse) {
      fetchOrderPermits();
    }
  }, [order?.userDeliveryResponse]);

  const items = order?.items.map((orderItem: any, index: number) => {
    const price =
      orderItem.priceWithDiscount && orderItem.priceWithDiscount > 0
        ? orderItem.unitPriceWithDiscount
        : orderItem.unitPrice;
    return (
      <div className={styles.OrderItem} key={index}>
        <span className={styles.orderItemName}>
          {orderItem.itemResponse.name} {orderItem.itemResponse.itemAvailabilityCode}
        </span>
        {orderItem.price != null && (
          <span className={styles.countAndPrice}>
            {orderItem?.quantity}x {orderItem?.unitPrice?.toFixed(2)} RSD
          </span>
        )}
        {/*//for rfzoRequest with status !== requsted*/}
        {orderItem.rfzoParticipationPrice && (
          <span className={styles.countAndPrice}>
            {orderItem?.rfzoQuantity} kom. | {orderItem?.unitPrice?.toFixed(2)} RSD
          </span>
        )}
      </div>
    );
  });

  console.log(order);

  return (
    <>
      <div className={styles.OrderModal}>
        <div>Tel: {order?.userResponse?.phoneNumber}</div>
        <div className={`${styles.Column} ${styles.RightColumn}`}>
          <RightPart
            order={order}
            copyLBO={copyLBO}
            // rejectValidation={rejectValidation}
            // setRejectValidation={setRejectValidation}
            getNoteForUser={getNoteForUser}
            permitForStore={permitForStore}
            printOrderDetailsAction={printOrderDetailsAction}
            isAdmin={isAdmin}
            openConfirmationModal={openConfirmationModal}
            printOrderInfoAction={printOrderInfoAction}
            takenOrderAction={takenOrderAction}
            orderPreparedAction={orderPreparedAction}
            closeModal={closeModal}
          />
          <div className={styles.Items}>{items}</div>
          <div className={styles.OrderModalFooter}>
            <span>Ukupna cena:</span>
            <strong>{order.totalPrice.toFixed(2)} RSD</strong>
          </div>
        </div>
        {order.status === "CREATED" && (
          <>
            <label htmlFor="NoteFromUser" className={styles.Label}>
              Poruka za korisnika{" "}
              {/* {rejectValidation && <span style={{ color: "Red" }}>(obavezno kod oktazivanja porudzbine)</span>} */}
            </label>
            <div id="NoteForUser" className={styles.NoteForUser}>
              <div>
                <textarea
                  placeholder="Tekst poruke....."
                  className={styles.TextArea}
                  onChange={getNoteForUser}></textarea>
              </div>
            </div>
          </>
        )}
        {!archived && !isAdmin && (
          <ProccessingButtons
            order={order}
            printOrderDetailsAction={printOrderDetailsAction}
            isAdmin={isAdmin}
            acceptOrder={acceptOrder}
            openConfirmationModal={openConfirmationModal}
            printOrderInfoAction={printOrderInfoAction}
            takenOrderAction={takenOrderAction}
            orderPreparedAction={orderPreparedAction}
            closeModal={closeModal}
          />
        )}
      </div>
      {confirmationModalVisible && (
        <ConfirmationModal
          show={confirmationModalVisible}
          backdropClickHandler={closeConfirmationModal}
          order={order}
          noteForUser={noteForUser}
        />
      )}

      <div style={{ display: "none" }}>
        <PrintOrderDetails ref={printOrderDetailsRef} order={order} />
      </div>
      <div style={{ display: "none" }}>
        <PrintOrderInfo ref={printOrderInfoRef} order={order} />
      </div>

      {notificationMessageVisible && <NotificationMessage>LBO kopiran!</NotificationMessage>}
    </>
  );
};

export default OrderDetails;

const RightPart = ({ order, permitForStore }: any) => {
  const showDeliveryPermit =
    permitForStore &&
    (order.deliveryType == "PICK_UP_BY_FRIEND" ||
      order.deliveryType === "FAST_DELIVERY" ||
      order.deliveryType === "STANDARD_DELIVERY" ||
      order.deliveryType === "PENSION_CARD_DELIVERY");

  console.log(order, permitForStore);
  return (
    <div className={styles.RightColumnContentWrapper}>
      <div className={styles.UpperPart}>
        <div id="NoteFromUser" className={styles.ChatBubble}>
          <ChatBubble noteFromUserFlag>
            {order.noteForMarketplace ? (
              order.noteForMarketplace
            ) : (
              <span className={styles.NoNoteFromUser}>Korisnik nije uneo napomenu</span>
            )}
          </ChatBubble>
        </div>
        {(order.status === "PROCESSED" ||
          order.status === "PREPARED" ||
          order.status === "REJECTED" ||
          order.status === "TAKEN") &&
          order.noteForUser && <ChatBubble>{order.noteForUser}</ChatBubble>}
        {order.status === "CANCELED" && <ChatBubble order={order}>{order.noteForCanceledStatus}</ChatBubble>}
        <PrescriptionPhotos
          permitForStore={showDeliveryPermit ? permitForStore : null}
          prescriptionsLinks={order.prescriptionsLinks}
        />
      </div>

      {order.deliveryType == "PICK_UP_BY_FRIEND" && (
        <DeliveryPersonDetails
          firstName={order?.deliveryFriendFirstName}
          secondName={order?.deliveryFriendSecondName}
          phoneNumber={order?.deliveryFriendPhoneNumber}
          personalId={order?.deliveryFriendPersonalId}
        />
      )}
      {order.deliveryType == "STANDARD_DELIVERY" && (
        <DeliveryPersonDetails
          firstName={order?.userDeliveryResponse?.firstName}
          secondName={order?.userDeliveryResponse?.secondName}
          phoneNumber={order?.userDeliveryResponse?.phoneNumber}
          personalId={order?.userDeliveryResponse?.personalId}
        />
      )}
    </div>
  );
};

const ProccessingButtons = ({
  order,
  printOrderDetailsAction,
  printOrderInfoAction,
  isAdmin,
  acceptOrder,
  openConfirmationModal,
  orderPreparedAction,
  takenOrderAction,
  closeModal,
}: any) => {
  return (
    <div className={styles.OrderProcessingPart}>
      {order.status === "CREATED" && (
        <div className={styles.PrintActionsWrapper}>
          <Button buttonType="white" style={{ fontsize: "14px", margin: "10px 0" }} onClick={printOrderDetailsAction}>
            Lista za pakovanje
          </Button>
          <Button buttonType="white" style={{ fontsize: "14px", margin: "10px 0" }} onClick={printOrderInfoAction}>
            Detalji porudžbine
          </Button>
        </div>
      )}
      <>
        {order.status === "CREATED" && (
          <>
            <Button
              buttonType="blue"
              style={{ fontsize: "14px", margin: "10px 0" }}
              onClick={() => acceptOrder(20)}
              disabled={isAdmin}>
              Prihvati porudžbinu
            </Button>
            <Button
              buttonType="white"
              style={{ fontsize: "14px", margin: "10px 0" }}
              onClick={openConfirmationModal}
              disabled={isAdmin}>
              Odbaci porudžbinu
            </Button>
          </>
        )}
        {order.status === "PROCESSED" && (
          <>
            <Button
              buttonType="blue"
              style={{ fontsize: "14px", margin: "10px 0" }}
              onClick={takenOrderAction}
              disabled={isAdmin}>
              Preuzeto
            </Button>
            <Button
              buttonType="white"
              style={{ fontsize: "14px", margin: "10px 0" }}
              onClick={openConfirmationModal}
              disabled={isAdmin}>
              Otkaži porudžbinu
            </Button>
          </>
        )}
        {order.status === "PREPARED" && (
          <>
            <Button
              buttonType="blue"
              style={{ fontsize: "14px", margin: "10px 0" }}
              onClick={takenOrderAction}
              disabled={isAdmin}>
              Preuzeto
            </Button>
            <Button
              buttonType="white"
              style={{ fontsize: "14px", margin: "10px 0" }}
              onClick={openConfirmationModal}
              disabled={isAdmin}>
              Otkaži porudžbinu
            </Button>
          </>
        )}
        {(order.status === "TAKEN" || order.status === "REJECTED" || order.status === "CANCELED") && (
          <>
            <Button style={{ fontsize: "14px", margin: "10px 0" }} buttonType="blue" onClick={closeModal}>
              Zatvori
            </Button>
          </>
        )}
      </>
    </div>
  );
};

interface DeliveryPersonDetailsProps {
  firstName: string;
  secondName: string;
  phoneNumber: string;
  personalId: string;
}

const DeliveryPersonDetails = ({ firstName, secondName, phoneNumber, personalId }: DeliveryPersonDetailsProps) => {
  return (
    <div className={styles.deliveryPersonDetails}>
      <div className={styles.deliveryPersonDetailsHeader}>
        <BagIcon style={{ marginRight: "5px" }} /> <span>Preuzimanje u apoteci</span>
      </div>
      <div className={styles.header}>Rezervaciju preuzima:</div>

      <div className={styles.infoItem}>
        <div>Ime i prezime:</div>
        <strong>
          {firstName} {secondName}
        </strong>
      </div>
      <div className={styles.infoItem}>
        <div>Broj telefona:</div>
        <strong>{phoneNumber}</strong>
      </div>
      <div className={styles.infoItem}>
        <div>Broj LK:</div>
        <strong>{personalId}</strong>
      </div>
    </div>
  );
};
