import axios from "./../axios/axios";
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {patientsActions} from "./patients-slice";

export const fetchAllPatients = (id_branch_marketplace: number, id_marketplace: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(patientsActions.fetchAllPatientsStart());

        let URL = "/marketplace/favourite_users/" + id_marketplace + "/" + id_branch_marketplace

        axios.get(URL)
            .then(response => {
                dispatch(patientsActions.fetchAllPatientsSuccess(response.data));
            })
            .catch(error => {
                console.log("Error se desio " + error)
                dispatch(patientsActions.fetchAllPatientsFailed())
            })
    }
}