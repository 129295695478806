import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type userInfoType = {
    id: string;
    username: string;
    firstName: string;
    secondName: string;
    roles: string[];
}

export interface AuthState {
    token: string | null,
    loginError: boolean,
    loadingLogin: boolean,
    loginServerError: boolean,

    userInfo: userInfoType | null,
    loadingUserInfo: boolean,
    userInfoError: boolean,

    marketplaceInfo: any,
    loadingMarketplaceInfo: boolean,
    marketplaceInfoError: boolean,

    loadingForgetPassword: boolean,
    forgetPasswordError: boolean,

    isAdmin: boolean,
    isPharmacist: boolean
    isDriver: boolean
}

const initialState: AuthState = {
    token: null,
    loginError: false,
    loadingLogin: false,
    loginServerError: false,

    userInfo: {
        id: "",
        username: "",
        firstName: "",
        secondName: "",
        roles: []
    },
    loadingUserInfo: false,
    userInfoError: false,

    marketplaceInfo: null,
    loadingMarketplaceInfo: false,
    marketplaceInfoError: false,

    loadingForgetPassword: false,
    forgetPasswordError: false,

    isAdmin: false,
    isPharmacist: false,
    isDriver: false
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        loginStart: (state) => {
            state.loadingLogin = true;
        },
        userInfoFn: (state, action: PayloadAction<userInfoType | null>) => {
            state.userInfo = action.payload;
            state.loadingUserInfo = false;
            state.userInfoError = false;
        },
        fetchUserInfoStart: (state) => {
          state.loadingUserInfo = true;
          state.userInfoError = false;
        },
        fetchUserInfoFailed: (state) => {
            state.loadingUserInfo = false;
            state.loadingLogin = false;
            state.userInfoError = true;
        },

        loginSuccess: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
            state.loadingLogin = false;
            state.loginError = false;
        },
        loginFailed: (state) => {
            state.loadingLogin = false;
            state.loginError = true;
        },
        loginStatusReset: (state) => {
            state.loginError = false;
            state.loadingLogin = false;
            state.loginServerError = false;
        },
        logout: (state) => {
            // setCNCActiveStatus(state.marketplaceInfo.branchMarketplaceId, state.marketplaceInfo.markeplaceId, 0);
            authSlice.actions.userInfoFn(null);
            authSlice.caseReducers.logoutUser(state);
            authActions.marketplaceInfo(null);
        },
        logoutUser: (state) => {
            clearLocalStorage();
            state.token = null;
            state.loginError = false;
            state.loadingLogin = false;
        },
        fetchMarketplaceInfoStart: (state) => {
            state.loadingMarketplaceInfo = true;
            state.marketplaceInfoError = false;
        },
        marketplaceInfo: (state, action) => {
            state.marketplaceInfo = action.payload;
            state.loadingMarketplaceInfo = false;
            state.marketplaceInfoError = false;
        },
        fetchMarketplaceInfoFailed: (state, action) => {
            state.marketplaceInfoError = action.payload;
            state.loadingMarketplaceInfo = false;
            state.loadingLogin = false;
            state.marketplaceInfoError = true;
        },
        loginServerError: (state) => {
            state.loginError = false;
            state.loginServerError = true;
            state.loadingLogin = false;
        },
        setActiveStatus: (state, action) => {
            state.marketplaceInfo = {...state.marketplaceInfo, takesOrders: action.payload}
        },
        setIsAdmin: (state) => {
            state.isAdmin = true;
            state.isPharmacist = false;
            state.isDriver = false;
        },
        setIsPharmacist: (state) => {
            state.isAdmin = false;
            state.isPharmacist = true;
            state.isDriver = false;
        },
        setIsDriver: (state) => {
            state.isDriver = true;
            state.isAdmin = false;
            state.isPharmacist = false;
        },
        setRegularUser: state => {
            state.isAdmin = false;
            state.isPharmacist = false;
            state.isDriver = false;
        }
    }
});

const clearLocalStorage = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('firstName');
    localStorage.removeItem('secondName');
    localStorage.removeItem('roles');
    localStorage.removeItem('marketplaceId');
    localStorage.removeItem('branchMarketplaceId');
    localStorage.removeItem('boIpAddress');
}

export const authActions = authSlice.actions;


export default authSlice;