import React, {useEffect, useState} from 'react';
import styles from './Layout.module.scss';
import HeaderComponent from "../HeaderComponent/HeaderComponent";
import FooterComponent from "../FooterComponent/FooterComponent";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import Navbar from "../UI/Navbar/Navbar";
import SocketsConnectionsComponent from "../SocketsConnectionsComponent/SocketsConnectionsComponent";

type Props = {
    children?: React.ReactNode;
};

const Layout: React.FC<Props> = ({children}, props) => {

    const isLogin = useSelector((state: RootState) => state.auth.token);
    const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);

    const [isAdminState, setIsAdminState] = useState(isAdmin);

    useEffect(() => {
        if (isAdmin || localStorage.getItem("roles")?.includes('ADMIN')) {
            setIsAdminState(true)
        }
    }, [isLogin])

    return (
        <div className={styles.Layout} {...props}>
            {isLogin && <HeaderComponent/>}
            {isLogin && isAdminState &&
                <Navbar/>
            }
            <SocketsConnectionsComponent/>
            {children}
            <FooterComponent/>
        </div>
    );
};

export default Layout;