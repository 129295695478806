import React, {useMemo} from 'react';
import styles from "../../../ArticlesComponents/ArticlesTable.module.scss";
// @ts-ignore
import {useSortBy, useTable} from "react-table";

type Props = {
    articlesData: any[]
}
const RFZOArticlesTable = ({articlesData}: Props) => {

    const data = useMemo(() => {
        return articlesData.map((article: any) => {
            return (
                {
                    col1: article.itemResponse.name,
                    col2: article.rfzotherapiesQuantity,
                    col3: article.stockQuantity
                }
            )
        })
    },[articlesData])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Naziv artikla',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Količina',
                accessor: 'col2',
            },
            {
                Header: 'Raspoloživa količina',
                accessor: 'col3',
            },
        ],
        []
    );

    const tableInstance = useTable({columns, data}, useSortBy)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    return (
        <table {...getTableProps()} style={{border: 'solid 1px blue'}}>
            <thead>
            {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            <div style={{display:"flex", justifyContent:"center"}}>{column.render('Header')}
                                <span>
                                    {column.isSorted
                                        ? (column.isSortedDesc
                                            ? ' ▼'
                                            : ' ▲')
                                        : ''}
                                </span>
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} className={styles.Tr}>
                        {row.cells.map((cell: any) => {
                            return (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
};

export default RFZOArticlesTable;
