import React from 'react';
import styles from './NotificationMessage.module.scss';

type Props = {
    children: React.ReactNode;
    onClick?: React.MouseEventHandler;
}

const NotificationMessage = ({children, onClick}:Props) => {

    return (
        <div className={styles.NotificationMessage} onClick={onClick}>
            {children}
        </div>
    );
};

export default NotificationMessage;