import React, {useState} from 'react';
import styles from './PushNotifications.module.scss'
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {sendPushNotification} from "../../store/admin-cms-actions";
import Button from "../UI/Buttons/Button/Button";


const PushNotifications = () => {

    const dispatch = useDispatch();

    const [sendToAllChecked, setSendToAllChecked] = useState(false)

    const {register, handleSubmit, formState: {errors, isValid}} = useForm();
    const onSubmit = (data: any) => {

        if (isValid) {

            const dataToSend = {
                userIds: sendToAllChecked ? null : data.userIds.split(',').map((id: any) => {
                    return Number(id)
                }),
                title: data.title,
                message: data.message,
                actionType: data.actionType,
                actionLink: data.actionLink
            }

            dispatch<any>(sendPushNotification(dataToSend))
        }

        // console.log(data)
    };
    // console.log(errors);


    return (
        <div className={styles.PushNotificationsWrapper}>
            <form onSubmit={handleSubmit(onSubmit)} style={{maxHeight: "100%", width: "100%"}}>
                <div className={styles.PushNotificationsFormWrapper}>
                    <div className={styles.PushNotificationsFormSection}>
                        <label htmlFor="sendToAll" className={styles.SendToAllLabel}>
                            <input type="checkbox"
                                   id="sendToAll"
                                   className={styles.SendToAll}
                                   placeholder="Pošalji svima"
                                   {...register("sendToAll", {})}
                                   checked={sendToAllChecked}
                                   onChange={() => setSendToAllChecked(prev => !prev)}
                            />
                            &nbsp;Pošalji svima
                        </label>

                        {!sendToAllChecked &&
                            <div>
                                <textarea  {...register("userIds", {
                                        required: {
                                            value: !sendToAllChecked,
                                            message: 'Obavezno je ili označiti \'Pošalji svima\' ili navesti barem jedan id koriniska'
                                        },
                                        pattern: {
                                            value: /^\d+(,\d+)*$/,
                                            message: "Možeš uneti samo cifre razdvojene zapetama"
                                        }

                                    }
                                )}
                                          style={{width: "100%"}}
                                          placeholder="Unesi ID brojeve korisnika razdvojene zapetom"
                                          rows={10}
                                />
                                {//@ts-ignore
                                 errors?.userIds && <div className={styles.ValidationErrorMessage}>{errors.userIds.message}</div>
                                }
                            </div>
                        }

                    </div>
                    <div className={styles.PushNotificationsFormSection}>
                        <div>
                        <input type="text" placeholder="Unesi naslov poruke, max. 300 karaktera" {...register("title", {
                            required: {
                                value: true,
                                message: 'Polje je obavezno'
                            },
                            maxLength: 300
                        })} />
                        {//@ts-ignore
                            errors?.title && <div className={styles.ValidationErrorMessage}>{errors.title.message}</div>
                        }
                        </div>
                        <div>
                        <textarea {...register("message", {
                            required: {
                                value: true,
                                message: 'Polje je obavezno'
                            },
                            maxLength: 500})
                        }
                                  style={{width: "100%"}}
                                  placeholder="Unesi tekst poruke, max. 500 karaktera"
                                  rows={10}
                        />
                        {//@ts-ignore
                            errors?.message && <div className={styles.ValidationErrorMessage}>{errors.message.message}</div>
                        }
                        </div>
                    </div>
                    <div className={styles.PushNotificationsFormSection}>
                        <div>
                        <select {...register("actionType", {
                            required: {
                                value: true,
                                message: 'Polje je obavezno'
                            },
                        })}>
                            <option disabled selected value=''>Izaberite tip</option>
                            <option value="ExtURL">ExtURL</option>
                            <option value="Dostupnost">Dostupnost</option>
                            <option value="Search">Search</option>
                            <option value="RFZO">RFZO</option>
                            <option value="Loyalty">Loyalty</option>
                            <option value="InfoLek">InfoLek</option>
                            <option value="Kategorija">Kategorija</option>
                            <option value="FilterProizvod">FilterProizvod</option>
                            <option value="FilterApoteka">FilterApoteka</option>
                            <option value="ShopApoteka">ShopApoteka</option>
                        </select>
                        {//@ts-ignore
                            errors?.actionType && <div className={styles.ValidationErrorMessage}>{errors.actionType.message}</div>
                        }
                        </div>
                        <div>
                        <input type="text" placeholder="Action link" {...register("actionLink", {
                            required: {
                                value: true,
                                message: 'Polje je obavezno'
                            },
                        })} />
                        {//@ts-ignore
                            errors?.actionLink && <div className={styles.ValidationErrorMessage}>{errors.actionLink.message}</div>
                        }
                        </div>
                    </div>

                </div>
                <Button buttonType="blue" style={{width:"300px", float:"right", marginTop:"10vh"}} type="submit">Pošalji</Button>

            </form>
        </div>
    );
};

export default PushNotifications;