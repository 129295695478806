import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import styles from "../ArticlesOutletPage/ArticlesOutletPage.module.scss";
import Select from "react-select";
import { fetchAllBranchMarketplaces} from "../../store/admin-cms-actions";
import ArticlesForBranchMarketplace
    from "../../components/ArticlesComponents/ArticlesForBranchMarketplace/ArticlesForBranchMarketplace";

const ArticlesVisibilityPage = () => {

    const dispatch = useDispatch()
    const branchMarketplacesForSelect = useSelector((state: RootState) => state.adminCMS.branchMarketplaceArrayForSelect);

    const [branchMarketplaceId, setBranchMarketplaceId] = useState<number>(-1)

    useEffect(() => {
        if (branchMarketplacesForSelect.length === 0) {
            dispatch<any>(fetchAllBranchMarketplaces())
        }
    }, []);

    const takeBranchMarketplaceValue = (e: any) => {
        setBranchMarketplaceId(e.value)
    }

    return (
        <>
            <div className={styles.ArticlesOutletPage}>
                <div className={styles.ComponentHeader}>
                    <Select
                        placeholder={<div>Izaberite pravno lice</div>}
                        options={branchMarketplacesForSelect}
                        openMenuOnClick={true}
                        className={styles.ReactSelect}
                        menuPosition={"fixed"}
                        onChange={takeBranchMarketplaceValue}
                    />
                </div>

                {branchMarketplaceId !== -1 &&
                    <ArticlesForBranchMarketplace branchMarketplaceId={branchMarketplaceId}/>
                }
            </div>
        </>
    );
};

export default ArticlesVisibilityPage;