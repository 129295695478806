import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export type RFZOArticleForMessage = {
    itemId: number,
    rfzoQuantity: number,
    rfzoParticipationPrice: number
}

export interface RFZOArticle extends RFZOArticleForMessage {
    articleName: string,
}


export interface rfzoTherapyOrderState {
    rfzoTherapyRequests: any[],
    rfzoTherapyRequestsFailed: boolean,
    loadingRFZOTherapies: boolean,

    newRFZOTherapyFailed: boolean,
    loadingNewRFZOTherapy: boolean,

    changeRFZOTherapyStatusFailed: boolean,
    loadingChangeRFZOTherapyStatus: boolean,

    rfzoSocketConnected: boolean,
    rfzoSocketConnectionStart: boolean,

    rfzoRequest: any | null,

    newRFZORequestNotification: boolean,

    fetchItemNamesStart: boolean,
    itemNames: any[],
    fetchItemNamesFailed: boolean,

    selectedItem: any,

    showAddRFZOArticleModal: boolean,
    therapyForUpdate: any,
    showTherapySentModal: false,

    articlesForRFZO: RFZOArticle[],
    articlesForRFZOMessage: RFZOArticleForMessage[],

    sendAnswerForRFZORequestStart: boolean,
    sendAnswerForRFZORequestSuccess: boolean,
    sendAnswerForRFZORequestFailed: boolean,

    loadingRFZOUpcomingUsers: boolean,
    rfzoUpcomingUsers: any[],
    fetchRFZOUpcomingUsersFailed: boolean,

    loadingRFZOUpcomingArticles: boolean,
    rfzoUpcomingArticles: any[],
    fetchRFZOUpcomingArticlesFailed: boolean,

    rejectRFZORequestStart: boolean,
    rejectRFZORequestSuccess: boolean,
    rejectRFZORequestFailed: boolean,
}

const initialState: rfzoTherapyOrderState = {
    rfzoTherapyRequests: [],
    rfzoTherapyRequestsFailed: false,
    loadingRFZOTherapies: false,

    newRFZOTherapyFailed: false,
    loadingNewRFZOTherapy: false,

    changeRFZOTherapyStatusFailed: false,
    loadingChangeRFZOTherapyStatus: false,

    rfzoSocketConnected: false,
    rfzoSocketConnectionStart: false,

    rfzoRequest: null,

    newRFZORequestNotification: false,

    fetchItemNamesStart: false,
    itemNames: [],
    fetchItemNamesFailed: false,

    selectedItem: null,

    showAddRFZOArticleModal: false,
    therapyForUpdate: null,
    showTherapySentModal: false,

    articlesForRFZO: [],
    articlesForRFZOMessage: [],

    sendAnswerForRFZORequestStart: false,
    sendAnswerForRFZORequestSuccess: false,
    sendAnswerForRFZORequestFailed: false,

    loadingRFZOUpcomingUsers: false,
    rfzoUpcomingUsers: [],
    fetchRFZOUpcomingUsersFailed: false,

    loadingRFZOUpcomingArticles: false,
    rfzoUpcomingArticles: [],
    fetchRFZOUpcomingArticlesFailed: false,

    rejectRFZORequestStart: false,
    rejectRFZORequestSuccess: false,
    rejectRFZORequestFailed: false,
}

const rfzoTherapySlice = createSlice({
    name: 'rfzoTherapySlice',
    initialState: initialState,
    reducers: {
        fetchRFZOTherapyRequestsStart:(state) => {
            state.rfzoTherapyRequestsFailed = false;
            state.loadingRFZOTherapies = true;
        },
        RFZOTherapyRequests:(state, action) => {
            let temp = action.payload;
            temp.sort((a:any, b:any) => moment(a.created).isBefore(moment(b.created)) ? 1 : -1)
            state.rfzoTherapyRequests = temp;
            state.rfzoTherapyRequestsFailed = false;
            state.loadingRFZOTherapies = false;
        },
        fetchRFZOTherapyRequestsFailed: (state) => {
            state.rfzoTherapyRequestsFailed = true;
            state.loadingRFZOTherapies = false;
        },
        changeRFZOTherapyStatusStart: (state) => {
            state.changeRFZOTherapyStatusFailed = false;
            state.loadingChangeRFZOTherapyStatus = true;
        },
        rfzoTherapyStatusChanged: (state, action) => {
            state.rfzoTherapyRequests = state.rfzoTherapyRequests.map((rfzoTherapy) => {
                let match:boolean | number = 0;
                action.payload.rfzoTherapyIds.forEach((rfzoTherapyId:number) => {
                    if (rfzoTherapyId === rfzoTherapy.id) {
                        match = true;
                    }
                })
                if (match) {
                    return {
                        ...rfzoTherapy,
                        status: action.payload.newStatus
                    }
                } else {
                    return {...rfzoTherapy};
                }
            });
            state.changeRFZOTherapyStatusFailed = false;
            state.loadingChangeRFZOTherapyStatus = false;
        },
        changeRFZOTherapyStatusFailed: (state) => {
            state.changeRFZOTherapyStatusFailed = true;
            state.loadingChangeRFZOTherapyStatus = false;
        },
        fetchNewRFZOTherapyStart:(state) => {
            state.newRFZOTherapyFailed = false;
            state.loadingNewRFZOTherapy = true;
        },
        rfzoTherapyReceived: (state, action) => {
            state.rfzoTherapyRequests = [...state.rfzoTherapyRequests, action.payload];
            state.newRFZOTherapyFailed = false;
            state.loadingNewRFZOTherapy = false;
        },
        fetchNewRFZOTherapyFailed: (state) => {
            state.newRFZOTherapyFailed = true;
            state.loadingNewRFZOTherapy = false;
        },
        rfzoTherapyDeleted: (state, action) => {
            state.rfzoTherapyRequests = state.rfzoTherapyRequests.filter(rfzoTherapy => {
                return +rfzoTherapy.id !== +action.payload
            });
            state.newRFZOTherapyFailed = false;
            state.loadingNewRFZOTherapy = false;
        },
        rfzoTherapyOrderReceived: (state, action) => {
            state.rfzoTherapyRequests = state.rfzoTherapyRequests.filter(rfzoTherapyRequest => {
                return +rfzoTherapyRequest.id !== +action.payload.rfzoTherapyNumber
            })
        },
        rfzoSocketConnectionChanged: (state, action) => {
            state.rfzoSocketConnected = action.payload;
            state.rfzoSocketConnectionStart = false;
        },
        rfzoSocketConnectionStart: (state) => {
            state.rfzoSocketConnectionStart = true;
        },
        toggleRFZORequestModal: (state, action) => {
            state.rfzoRequest = action.payload
        },
        newRFZORequestDesktopNotification: (state, action) => {
            state.newRFZORequestNotification = action.payload
        },
        fetchItemNamesStart: (state) => {
            state.fetchItemNamesStart = true;
            state.itemNames = [];
            state.fetchItemNamesFailed = false;
        },
        fetchItemNamesSuccess: (state, action) => {
            state.fetchItemNamesStart = false;
            state.itemNames = action.payload;
            state.fetchItemNamesFailed = false;
        },
        fetchItemNamesFailed: (state) => {
            state.fetchItemNamesStart = false;
            state.itemNames = [];
            state.fetchItemNamesFailed = true;
        },
        setSelectedItem: (state,action) => {
            state.selectedItem = action.payload;
        },
        toggleAddItemModal: (state, action) => {
            state.showAddRFZOArticleModal = action.payload
        },
        addArticleToRFZORequest: (state, action) => {
            state.articlesForRFZO.push(action.payload);
        },
        removeArticleFromRFZORequest: (state, action) => {
            state.articlesForRFZO = state.articlesForRFZO.filter(article => {
                if(article.itemId !== action.payload) {
                    return article
                }
            })
        },
        trimRFZOArticlesForMessage: (state) => {
            state.articlesForRFZOMessage = state.articlesForRFZO.map(
                ({itemId, rfzoQuantity, rfzoParticipationPrice}:RFZOArticle) => ({itemId, rfzoQuantity, rfzoParticipationPrice})
            );
        },
        resetRFZOArticlesList: (state) => {
            state.articlesForRFZO = []
        },
        sendAnswerForRFZORequestStart: (state) => {
                state.sendAnswerForRFZORequestStart = true;
                state.sendAnswerForRFZORequestSuccess = false;
                state.sendAnswerForRFZORequestFailed = false;
        },
        sendAnswerForRFZOTherapySuccess: (state) => {
                state.sendAnswerForRFZORequestStart = false;
                state.sendAnswerForRFZORequestSuccess = true;
                state.sendAnswerForRFZORequestFailed = false;
        },
        sendAnswerForRFZORequestFailed: (state) => {
                state.sendAnswerForRFZORequestStart = false;
                state.sendAnswerForRFZORequestSuccess = false;
                state.sendAnswerForRFZORequestFailed = true;
        },
        fetchRFZOUpcomingUsersStart: (state) => {
            state.loadingRFZOUpcomingUsers = true;
            state.fetchRFZOUpcomingUsersFailed = false;
        },
        fetchRFZOUpcomingUsersSuccess: (state, action) => {
            state.loadingRFZOUpcomingUsers = false;
            state.rfzoUpcomingUsers = action.payload;
            state.fetchRFZOUpcomingUsersFailed = false;
        },
        fetchRFZOUpcomingUsersFailed: (state) => {
            state.loadingRFZOUpcomingUsers = false;
            state.fetchRFZOUpcomingUsersFailed = true;
        },
        fetchRFZOUpcomingArticlesStart: (state) => {
            state.loadingRFZOUpcomingArticles = true;
            state.fetchRFZOUpcomingArticlesFailed = false;
        },
        fetchRFZOUpcomingArticlesSuccess: (state, action) => {
            state.loadingRFZOUpcomingArticles = false;
            state.rfzoUpcomingArticles = action.payload;
            state.fetchRFZOUpcomingArticlesFailed = false;
        },
        fetchRFZOUpcomingArticlesFailed: (state) => {
            state.loadingRFZOUpcomingArticles = false;
            state.fetchRFZOUpcomingArticlesFailed = true;
        },
        rejectRFZORequestStart: (state) => {
            state.rejectRFZORequestStart = true;
            state.rejectRFZORequestSuccess = false;
            state.rejectRFZORequestFailed = false;
        },
        rejectRFZORequestSuccess: (state) => {
            state.rejectRFZORequestStart = false;
            state.rejectRFZORequestSuccess = true;
            state.rejectRFZORequestFailed = false;
        },
        rejectRFZORequestFailed: (state) => {
            state.rejectRFZORequestStart = false;
            state.rejectRFZORequestSuccess = false;
            state.rejectRFZORequestFailed = true;
        }
    }
});

export const rfzoTherapyActions = rfzoTherapySlice.actions

export default rfzoTherapySlice