import React, { useRef, useState } from "react";
import styles from "./LoginPane.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../store/auth-actions";
import Button from "../../UI/Buttons/Button/Button";
import { authActions } from "../../../store/auth-slice";
import { RootState } from "../../../store";

const LoginPane = () => {
  const dispatch = useDispatch();

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [enteredUsernameIsValid, setEnteredUsernameIsValid] = useState(true);
  const [enterPasswordIsValid, setEnterPasswordIsValid] = useState(true);

  const loginFailed = useSelector((state: RootState) => state.auth.loginError);

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();

    const credentials = {
      email: usernameInputRef.current!.value,
      password: passwordInputRef.current!.value,
    };

    if (credentials.email.trim() === "") {
      setEnteredUsernameIsValid(false);
      if (credentials.password.trim() === "") {
        setEnterPasswordIsValid(false);
      } else {
        setEnterPasswordIsValid(true);
      }
      return;
    }
    if (credentials.password.trim() === "") {
      setEnteredUsernameIsValid(true);
      setEnterPasswordIsValid(false);
      return;
    }

    setEnteredUsernameIsValid(true);
    setEnterPasswordIsValid(true);
    dispatch<any>(login(credentials));
  };

  const resetLoginStatus = () => dispatch(authActions.loginStatusReset());

  return (
    <div className={styles.loginPageWrap}>
      <div className={styles.imageWrap}>
        <img
          src="/login-image-1.png"
          srcSet="/login-image-1.png 480w, /login-image-2.png 1200w"
          sizes="(max-width: 600px) 480px, (min-width: 601px) 1200px"
          alt="cover"
        />
      </div>
      <div className={styles.formWrap}>
        <form onSubmit={submitHandler} className={styles.LoginForm}>
          <h2 className={styles.header}>Prijavi se</h2>
          <div className={styles.subHeader}>Dobrodošli! Unesite svoje kredencijale.</div>
          <div className={styles.InputFiledWrapper}>
            <input
              className={styles.InputFiled}
              ref={usernameInputRef}
              type="text"
              id="username"
              placeholder="Email"
              autoComplete="off"
              onFocus={resetLoginStatus}
            />
            <div className={styles.ValidationMessage}>
              {" "}
              {!enteredUsernameIsValid && "Ovo polje ne može biti prazno"}
            </div>
          </div>
          <div className={styles.InputFiledWrapper}>
            <input
              className={styles.InputFiled}
              ref={passwordInputRef}
              type="password"
              id="password"
              placeholder="Lozinka"
              autoComplete="off"
              onFocus={resetLoginStatus}
            />
            <div className={styles.ValidationMessage}> {!enterPasswordIsValid && "Ovo polje ne može biti prazno"}</div>
          </div>
          <div className={styles.ValidationMessage} style={{ textAlign: "center", width: "fit-content" }}>
            {loginFailed && "Korisničko ime ili lozinka nisu ispravni"}
          </div>
          <Button buttonType="blue">Prijavi se</Button>
        </form>
      </div>
    </div>
  );
};

export default LoginPane;
