import React from 'react';
import logo from "../../../assets/Apoteka-logo-BW.svg"

const LogoBW = () => {
    return (
        <div>
            <img src={logo} alt="apoteka logo" style={{width:"200px"}}/>
        </div>
    );
};

export default LogoBW;
