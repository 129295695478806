import React, {useEffect} from 'react';
import styles from './SearchItem.module.scss';
import {useDispatch} from "react-redux";
import {rfzoTherapyActions} from "../../../../../../../store/rfzoTherapy-slice";

type Props = {
    close?: any,
    item: any,
    enter?: any,
    customClickHandler?: Function
}

const SearchItem = ({close, item, enter, customClickHandler}:Props) => {

    const dispatch = useDispatch();

    const clickItemHandler = (item:any) => {
        dispatch(rfzoTherapyActions.setSelectedItem(item))
        close();
        dispatch(rfzoTherapyActions.toggleAddItemModal(true))
    }

    useEffect(() => {
        if (enter) {
            clickItemHandler(enter)
        }
    }, [enter])

    return (
        <>
            <div className={styles.SearchItem} data-toggle="modal" onClick={() => {
                
                if(customClickHandler) {
                    customClickHandler(item)
                } else {
                    clickItemHandler(item)}
                }
                } >
                <span key={item.id} className={styles.SearchItemText}>
                    {item.name}
                </span>
            </div>
        </>
    );
};

export default SearchItem;