import React, { useEffect, useState } from "react";
import styles from "./RFZOOrderDetails.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchItemNames, sendAnswerForRFZORequest } from "../../store/rfzoTherapy-actions";
import Button from "../UI/Buttons/Button/Button";
import ConfirmationModal from "../UI/Modals/ConfirmationModal/ConfirmationModal";
import NotificationMessage from "../UI/NotificationMessage/NotificationMessage";
import RFZOArticleModal from "../UI/Modals/RFZOArticleModal/RFZOArticleModal";
import AddRFZOArticles from "../UI/Modals/RFZOTherapyRequestModal/AddRFZOArticles/AddRFZOArticles";
import RFZOOrderTherapy from "../RFZOOrderTherapy/RFZOOrderTherapy";
import SimpleModal from "../UI/Modals/SimpleModal/SimpleModal";

type Props = {
  show: any;
  noBackdrop?: boolean;
  rfzoRequest: any;
  archived?: boolean;
};

const RFZOOrderDetails = ({ rfzoRequest, archived }: Props) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState<"INITIAL" | "HAVE_THERAPY" | "NO_THERAPY_MODAL" | "CURRENTLY_NOT" | "NOT_AT_ALL">(
    "INITIAL"
  );

  const showAddArticleModal = useSelector((state: RootState) => state.rfzoTherapy.showAddRFZOArticleModal);

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [notificationMessageVisible, setNotificationMessageVisible] = useState(false);
  const [sendAnswerModalVisible, setSendAnswerModalVisible] = useState(false);

  useEffect(() => {
    dispatch<any>(fetchItemNames());
  }, []);

  const copyLBO = () => {
    navigator.clipboard.writeText(rfzoRequest.userLBO);
    setNotificationMessageVisible(true);
    setTimeout(() => {
      setNotificationMessageVisible(false);
    }, 5000);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  console.log(step);

  const openSendModal = () => {
    setSendAnswerModalVisible(true);
  };

  if (step == "INITIAL") {
    return (
      <>
        <div className={styles.RFZOTherapyRequestModal}>
          <div className={`${styles.Column} ${styles.LeftColumn}`}>
            <div>Tel: {rfzoRequest.phoneNumber}</div>
            <div>LBO: {rfzoRequest.userLBO}</div>
          </div>
          <div className={`${styles.Column} ${styles.RightColumn}`}>
            <div className={styles.RightColumnContentWrapper}>
              <div className={styles.RFZOTherapyRequestModalMessage}>
                <Button style={{ fontSize: "14px", background: "#DEFFFF" }} buttonType="white" onClick={copyLBO}>
                  Kopiraj LBO i na portalu eApoteka proveri dostupnost terapije
                </Button>
              </div>
              <div className={styles.RFZOTherapyRequestActions}>
                <Button buttonType="blue" onClick={() => setStep("HAVE_THERAPY")}>
                  Korisnik ima terapiju
                </Button>
                <Button buttonType="white" onClick={() => setStep("NO_THERAPY_MODAL")}>
                  Korisnik nema terapiju
                </Button>
              </div>
            </div>
          </div>
        </div>

        {confirmationModalVisible && (
          <ConfirmationModal
            show={confirmationModalVisible}
            backdropClickHandler={closeConfirmationModal}
            order={rfzoRequest}
          />
        )}

        {notificationMessageVisible && <NotificationMessage>LBO kopiran!</NotificationMessage>}

        {showAddArticleModal && <RFZOArticleModal show={showAddArticleModal} />}
      </>
    );
  }

  if (step === "HAVE_THERAPY") {
    return (
      <>
        <div className={styles.RFZOTherapyRequestModal}>
          <div>Tel: {rfzoRequest.phoneNumber}</div>
          <div>LBO: {rfzoRequest.userLBO}</div>
          <AddRFZOArticles header="Trenutna terapija" />
          <div className={styles.RightColumnContentWrapper}>
            <RFZOOrderTherapy hasTherapy={true} backdropClickHandler={() => {}} show={true} rfzoId={rfzoRequest.id} />
            <div className={styles.RFZOTherapyRequestActions}></div>
          </div>
        </div>

        {confirmationModalVisible && (
          <ConfirmationModal
            show={confirmationModalVisible}
            backdropClickHandler={closeConfirmationModal}
            order={rfzoRequest}
          />
        )}

        {notificationMessageVisible && <NotificationMessage>LBO kopiran!</NotificationMessage>}

        {showAddArticleModal && <RFZOArticleModal show={showAddArticleModal} />}
      </>
    );
  }

  if (step === "NO_THERAPY_MODAL") {
    return (
      <SimpleModal backdropClickHandler={() => {setStep("INITIAL")}} className={styles.noTherapyModal} show={true}>
        <Button onClick={() => setStep("CURRENTLY_NOT")} className={styles.noTherapyModalButton} buttonType="blue">
          Korisniku trenutno nije dospela terapija
        </Button>
        <Button onClick={() => setStep("NOT_AT_ALL")} className={styles.noTherapyModalButton} buttonType="blue">
          Korisnik nema propisanu terapiju
        </Button>
      </SimpleModal>
    );
  }
  if (step === "CURRENTLY_NOT") {
    return (
      <>
        <div className={styles.RFZOTherapyRequestModal}>
          <div>Tel: {rfzoRequest.phoneNumber}</div>
          <div>LBO: {rfzoRequest.userLBO}</div>
          <div className={styles.RightColumnContentWrapper}>
            <div className={styles.RFZOTherapyRequestModalMessage}></div>
            <RFZOOrderTherapy hasTherapy={false} backdropClickHandler={() => {}} show={true} rfzoId={rfzoRequest.id} />
            <div className={styles.RFZOTherapyRequestActions}></div>
          </div>
        </div>

        {confirmationModalVisible && (
          <ConfirmationModal
            show={confirmationModalVisible}
            backdropClickHandler={closeConfirmationModal}
            order={rfzoRequest}
          />
        )}

        {notificationMessageVisible && <NotificationMessage>LBO kopiran!</NotificationMessage>}

        {showAddArticleModal && <RFZOArticleModal show={showAddArticleModal} />}
      </>
    );
  }
  if (step === "NOT_AT_ALL") {
    return (
      <SimpleModal className={styles.noTherapyModal} show={true}>
        <div className={styles.explanation}>
          Korisnik će biti obavešten da nema propisanu terapiju i da u slučaju da mu je potrebna terapija, treba da
          poseti lekara.
        </div>
        <Button
          onClick={() => {
            dispatch<any>(sendAnswerForRFZORequest(rfzoRequest.id, { items: [] }));
          }}
          className={styles.noTherapyModalButton}
          buttonType="blue">
          Ok
        </Button>
      </SimpleModal>
    );
  }

  return <>Dialog</>;
};

export default RFZOOrderDetails;
