import React from 'react';
import styles from './AddRFZOArticles.module.scss';
import SearchBar from "../SearchWrapper/SearchBar/SearchBar";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../store";
import ArticleItem from "../../../../ArticleItem/ArticleItem";

const AddRFZOArticles = ({header}: any) => {

    const itemNames = useSelector((state:RootState) => state.rfzoTherapy.itemNames);
    const articlesForRFZO = useSelector((state:RootState) => state.rfzoTherapy.articlesForRFZO)

    return (
        <div className={styles.AddRFZOArticles}>
            <div className={styles.header}>{header}</div>
            <SearchBar data={itemNames}/>
            {articlesForRFZO.map((article, index:number) => {
                return (
                    <ArticleItem article={article} key={index} xIcon />
                )
            })}
        </div>
    );
};

export default AddRFZOArticles;