import React from 'react';
import styles from './SimpleModal.module.scss'
import Backdrop from "../../Backdrop/Backdrop";
import { ReactComponent as XIcon } from "../../../../assets/icons/x-icon.svg";
type Props = {
    children?: React.ReactNode,
    show: boolean,
    backdropClickHandler?: React.MouseEventHandler,
    className?: string,
}

const SimpleModal = ({children, show, backdropClickHandler, className}: Props) => {

    return (
        <>
            <div className={`${styles.SimpleModal} ${className}`}>
                {backdropClickHandler && <XIcon className={styles.XIcon} onClick={backdropClickHandler}/>}
                {children}
            </div>
            {show &&
                <Backdrop show={show}
                          zIndex={45}
                          backgroundColor="rgba(0, 0, 0, .2)"
                          backdropClickHandler={backdropClickHandler}/>
            }
        </>

    );
};

export default SimpleModal;