import React, {useEffect, useRef, useState} from 'react';
import ColumnComponent from "../../ColumnComponent/ColumnComponent";
import {RootState} from "../../../../store";
import {useDispatch, useSelector} from "react-redux";
import OrderBox from "../../OrderBoxV2/OrderBoxV2";
import PerfectScrollbar from 'react-perfect-scrollbar';
import orderReceivedSound from "../../../../assets/sounds/jingle.wav"
import {orderActions} from "../../../../store/order-slice";
import {rfzoTherapyActions} from "../../../../store/rfzoTherapy-slice";
import styles from "./ReceivedCoulmn.module.scss";

type Props = {
    isAdmin?: boolean
}

const ReceivedColumn = ({isAdmin}: Props) => {

    const orderState = useSelector((state:RootState) => state.order);
    const rfzoTherapy = useSelector((state:RootState) => state.rfzoTherapy);
    const newOrderDesktopNotification = useSelector((state:RootState) => state.order.newOrderNotification)
    const newRFZORequestNotification = useSelector((state:RootState) => state.rfzoTherapy.newRFZORequestNotification)
    const dispatch = useDispatch();

    const [orders, setOrders] = useState<any>(<div>Waiting...</div>);
    const [rfzoRequest, setRfzoRequest] = useState<any>("")

    const [isPlaying, setIsPlaying] = useState(false);
    const intervalRef = useRef<NodeJS.Timeout>();
    const audioRef = useRef<HTMLAudioElement>();

    const [oldestOrderTime, setOldestOrderTime] = useState<any>();
    const [minutes, setMinutes] = useState<any>();
    const [seconds, setSeconds] = useState<any>();
    const [countDownAlertColor, setCountDownAlertColor] = useState(false)

    useEffect(() => {
        if (!audioRef.current) {
            audioRef.current = new Audio(orderReceivedSound);
            audioRef.current.volume = 0.1;
            audioRef.current.loop = false;
        }

        if (isPlaying && audioRef.current) {
            audioRef.current.play();
            intervalRef.current = setInterval(() => {
                if (audioRef.current) {
                    audioRef.current.currentTime = 0;
                    audioRef.current.play();
                }
            }, 30000);
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
            }
            clearInterval(intervalRef.current);
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [isPlaying, orderReceivedSound]);

    useEffect(() => {
        if (orderState.receivedOrders.length > 0) {
            setIsPlaying(true);
        } else {
            setIsPlaying(false);
        }
    }, [orderState, setIsPlaying]);
    // }, [orderState.receivedOrders]);

    useEffect(()=>{
        if(!orderState.loadingOrders) {
            if (orderState.ordersFailed) {
            //     tODO ovde prikaz ako se desila greska prilikom ucitavanja porudzbina
            } else {
                setOrders(orderState.receivedOrders.length > 0 && orderState.receivedOrders.map((order:any) => {
                    return(
                        <OrderBox key={order.id} order={order} isAdmin={isAdmin}/>
                    );
                }))
            }
        }
    }, [orderState.receivedOrders, orderState.loadingOrders]);

    useEffect(() => {
        if (orderState.receivedOrders.length === 1) {
            setOldestOrderTime(orderState.receivedOrders[0].created);
        } else if (orderState.receivedOrders.length > 0) {
            setOldestOrderTime(
                orderState.receivedOrders.reduce((oldestTime, order) => {
                    const orderTime = new Date(order.created).getTime();
                    return orderTime < oldestTime ? orderTime : oldestTime;
                }, new Date().getTime())
            );
        } else if (orderState.receivedOrders.length === 0) {
            setOldestOrderTime(null)
        }
    }, [orderState.receivedOrders]);

    useEffect(()=> {
        if(oldestOrderTime === null) {
            setMinutes('00')
            setSeconds('00')
        }
        const intervalId = setInterval(() => {
            if (oldestOrderTime) {
                let time = new Date(oldestOrderTime)
                const remainingTime = Math.floor( 600 - (Date.now()/1000 - time.getTime()/1000));
                if (remainingTime > 0 || remainingTime === null) {
                    setCountDownAlertColor(false)
                } else {
                    setCountDownAlertColor(true)
                }
                setMinutes(Math.abs(Math.floor(remainingTime/60)));
                setSeconds(Math.abs(remainingTime % 60) < 10 ? `0${Math.abs(remainingTime % 60)}` :Math.abs(remainingTime % 60))
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [oldestOrderTime])

    useEffect(()=> {
        if(!rfzoTherapy.loadingRFZOTherapies) {
            if (rfzoTherapy.rfzoTherapyRequestsFailed) {
                //todo ovde obrada greske priliko ucitavanja rfzo zahteva
            } else {
                if(rfzoTherapy.rfzoTherapyRequests.length >= 0)
                setRfzoRequest(rfzoTherapy.rfzoTherapyRequests.map((rfzoRequest: any, index: number) => {
                    if(rfzoRequest.status === 'REQUESTED') {
                        return (
                            <OrderBox key={index} rfzoRequest={rfzoRequest}/>
                        );
                    }
                }))
            }
        }
    }, [rfzoTherapy.rfzoTherapyRequests, rfzoTherapy.loadingRFZOTherapies] )

    useEffect(() => {
        setTimeout(() => {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission();
            }
        }, 3000)
    }, []);

    useEffect(() => {
        if(newOrderDesktopNotification) {
            if (Notification.permission === 'granted') {
                showNewOrderNotification();
            } else {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted')
                        showNewOrderNotification();
                })
            }
            dispatch(orderActions.newOrderDesktopNotification(false));
        }
    }, [newOrderDesktopNotification]);

    useEffect(()=> {
        if(newRFZORequestNotification) {
            if (Notification.permission === 'granted') {
                showNewRfzoNotification();
            } else {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted')
                        showNewRfzoNotification();
                })
            }
            dispatch(rfzoTherapyActions.newRFZORequestDesktopNotification(false));
        }
    }, [newRFZORequestNotification]);

    const onNotificationClick = () => {
        window.focus();
        window.location.href = "https://cnc.apoteka.rs"
    }

    const showNewOrderNotification = () => {
        // @ts-ignore
        const notification = new Notification('Stigla je nova porudžbina', {
            // body: "Stigla je nova porudžbina",
            icon: 'https://apoteka.rs/favicon.svg',
            requireInteraction: true,
        });
        notification.onclick = (e) => onNotificationClick();
    }

    const showNewRfzoNotification = () => {
        // @ts-ignore
        const notification = new Notification('Stigla je nova RFZO porudžbina', {
            // body: "Stigla je nova RFZO porudžbina",
            icon: 'https://apoteka.rs/favicon.svg',
            requireInteraction: true,
        });
        notification.onclick = (e) => onNotificationClick();
    }

    const rightSideContent =
        orderState.receivedOrders.length > 0 &&
            <span className={styles.RightSideHeader}>
            </span>

    const rfzoRequestRequested = rfzoTherapy.rfzoTherapyRequests.filter((rfzoRequest) => {
        return rfzoRequest.status === "REQUESTED"
    })

    return (
        <ColumnComponent leftSideLabel="Pristigle"
                         leftSideNumber={(orderState.receivedOrders.length + rfzoRequestRequested.length).toString()}
                         rightSide={
                             rightSideContent
                         }
                         style={{flex:"4"}}>
            <PerfectScrollbar style={{padding:"4px"}}>
                {orders}
                {rfzoRequest}
            </PerfectScrollbar>
        </ColumnComponent>
    );
};

export default ReceivedColumn;