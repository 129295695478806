import React, {ComponentProps} from 'react';
import styles from './PrintOrderInfo.module.scss';
import Logo from "../../../UI/Logo/LogoBW";

interface Props extends ComponentProps<any> {

}

export type Ref = HTMLDivElement;

const PrintOrderInfo = React.forwardRef<Ref, Props>((props, ref) => {
    return (
        <div ref={ref} className={styles.PrintWrapper}>
            <Logo/>
            <div>Broj porudžbine:</div>
            <div style={{fontSize:"80px", fontWeight:"bold"}}>#{props.order.id}</div>
            <div>Podaci o klijentu:</div>
            <div style={{fontWeight:"bold"}}>{props.order.firstName} {props.order.secondName}</div>
            <div style={{fontWeight:"bold"}}>{props.order.userPhoneNumberForOrder}</div>
            {props.order.userAddressResponse &&
                <div>{props.order.userAddressResponse.name} {props.order.userAddressResponse.number}/{props.order.userAddressResponse.apartment}, {props.order.userAddressResponse.city}</div>
            }
            <br/>
        </div>
    )
});

export default PrintOrderInfo;