import axios from "./../axios/axios";
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {rfzoTherapyActions} from "./rfzoTherapy-slice";


export const fetchRFZOTherapyRequests = (branchMarketplaceId:number, marketplaceId:number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return dispatch => {
        dispatch(rfzoTherapyActions.fetchRFZOTherapyRequestsStart());
        const URL = '/rfzo_therapy/' + branchMarketplaceId  + '/' + marketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyActions.RFZOTherapyRequests(response.data));

                let rfzoTherapyForPOS:any = [];

                // if (localStorage.getItem("boIpAddress") !== null) {
                //
                //     let rfzoTherapyNotSentToPOS = response.data.filter((rfzoTherapy:any)=> {
                //         return rfzoTherapy.status === "REQUESTED"
                //         }
                //     );
                //
                //     let rejectedRFZOTherapyNotSentToPOS = response.data.filter((rfzoTherapy:any) => {
                //         return rfzoTherapy.status === "REJECT_REQUESTED"
                //         }
                //     );
                //
                //     let counter = rfzoTherapyNotSentToPOS.length;
                //
                //     const config = {
                //         headers: {Authorization: "Bearer " + localStorage.getItem('token')}
                //     };
                //
                //     rfzoTherapyNotSentToPOS.forEach((rfzoTherapy:any) => {
                //         if (rfzoTherapy.status === "REQUESTED") {
                //             const URL = '/galen_job/rfzo_therapy/' + rfzoTherapy.id;
                //             axios.get(URL)
                //                 .then(response => {
                //                     console.log(response.data);
                //                     axios.post(localStorage.getItem("boIpAddress") + "/rfzo_therapy/create", {...response.data}, config)
                //                         .then(response => {
                //                             console.log(response.data);
                //                             rfzoTherapyForPOS.push(rfzoTherapy.id);
                //                             if (rfzoTherapyForPOS.length === counter) {
                //                                 dispatch(changeRFZOTherapyStatus("REQUEST_SENT_TO_POS", rfzoTherapyForPOS));
                //                             }
                //                         })
                //                         .catch(error => {
                //                             counter--;
                //                             if (rfzoTherapyForPOS.length === counter) {
                //                                 dispatch(changeRFZOTherapyStatus("REQUEST_SENT_TO_POS", rfzoTherapyForPOS));
                //                             }
                //                             // let responseData = {}
                //                             // if (error.response !== undefined) {
                //                             //     responseData = {
                //                             //         data: JSON.stringify(error.response.data),
                //                             //         status: '' + error.response.status + error.response.statusText,
                //                             //         headers: JSON.stringify(error.response.headers)
                //                             //     }
                //                             // }
                //                             // dispatch.onSendOrderPOSError([{
                //                             //     error: JSON.stringify(error),
                //                             //     ...responseData,
                //                             //     orderId: +message.content.cncOrderId
                //                             // }]);
                //                         })
                //
                //                 })
                //                 .catch(error => {
                //                     console.log("Error se desio" + error);
                //                 })
                //         }
                //     })
                //
                //     rejectedRFZOTherapyNotSentToPOS.forEach((rfzoTherapy:any) => {
                //         axios.post( localStorage.getItem("boIpAddress") + "/rfzo_therapy/cancel/" + rfzoTherapy.id, null, config)
                //             .then(response =>  {
                //                 console.log(response);
                //                 dispatch(changeRFZOTherapyStatus("REJECT_REQUEST_SENT_TO_POS", [rfzoTherapy.id]));
                //             })
                //             .catch(error => {
                //                 // let responseData = {}
                //                 // if (error.response !== undefined) {
                //                 //     responseData = {
                //                 //         data: JSON.stringify(error.response.data),
                //                 //         status: '' + error.response.status + error.response.statusText,
                //                 //         headers: JSON.stringify(error.response.headers)
                //                 //     }
                //                 // }
                //                 // props.onSendOrderPOSError([{
                //                 //     error: JSON.stringify(error),
                //                 //     ...responseData,
                //                 //     orderId: +message.content.cncOrderId
                //                 // }]);
                //             })
                //     });
                // }

            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(rfzoTherapyActions.fetchRFZOTherapyRequestsFailed())
            })
    };
}
export const fetchAllRFZOTherapyRequests = (): ThunkAction<void, RootState, unknown, AnyAction> => {

    return dispatch => {
        dispatch(rfzoTherapyActions.fetchRFZOTherapyRequestsStart());
        const URL = '/rfzo_therapy/';
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyActions.RFZOTherapyRequests(response.data));

            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(rfzoTherapyActions.fetchRFZOTherapyRequestsFailed())
            })
    };
}

export const changeRFZOTherapyStatus = (status:any, rfzoTherapyIds:number[]): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(rfzoTherapyActions.changeRFZOTherapyStatusStart());
        const URL = '/rfzo_therapy/status/' + status;
        axios.put(URL, rfzoTherapyIds)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyActions.rfzoTherapyStatusChanged({status:status, rfzoTherapyIds: rfzoTherapyIds}));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(rfzoTherapyActions.changeRFZOTherapyStatusFailed())
            })
    };
}

export const rejectRFZORequest = (rfzoRequestId:number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(rfzoTherapyActions.rejectRFZORequestStart());
        const URL = '/rfzo_therapy/cancel/' + rfzoRequestId;
        axios.put(URL)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyActions.rejectRFZORequestSuccess());
                dispatch(rfzoTherapyActions.toggleRFZORequestModal(false));
                //rfzoRequest status change, in order to move it to ArchivedColumn
                dispatch(rfzoTherapyActions.rfzoTherapyStatusChanged({status: 'CANCELED', rfzoTherapyIds: [rfzoRequestId]}))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(rfzoTherapyActions.rejectRFZORequestFailed())
            })
    };
}

export const fetchNewRFZOTherapy = (rfzoTherapyId:number): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(rfzoTherapyActions.fetchNewRFZOTherapyStart());
        const URL = '/rfzo_therapy/' + rfzoTherapyId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(rfzoTherapyActions.rfzoTherapyReceived(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(rfzoTherapyActions.fetchNewRFZOTherapyFailed())
            })
    };
}

export const fetchItemNames = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(rfzoTherapyActions.fetchItemNamesStart());
        const URL = '/item/names';
        axios.get(URL)
            .then(response => {
                dispatch(rfzoTherapyActions.fetchItemNamesSuccess(response.data))
            })
            .catch(error => {
                console.log("FetchItemNames error: " + error);
                dispatch(rfzoTherapyActions.fetchItemNamesFailed())
            })
    };
}

export const sendAnswerForRFZORequest = (rfzoTherapyId:number, rfzoMessage:any): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(rfzoTherapyActions.sendAnswerForRFZORequestStart());
        const URL = '/rfzo_therapy/' + rfzoTherapyId;
        axios.put(URL, rfzoMessage)
            .then(response => {
                dispatch(rfzoTherapyActions.sendAnswerForRFZOTherapySuccess());
                dispatch(rfzoTherapyActions.resetRFZOArticlesList());
                dispatch(rfzoTherapyActions.toggleRFZORequestModal(false));
                //rfzoRequest status change, in order to move it to ArchivedColumn
                dispatch(rfzoTherapyActions.rfzoTherapyStatusChanged({status: 'PROCESSED', rfzoTherapyIds: [rfzoTherapyId]}))
            })
            .catch(error => {
                console.log("Error se desio " + error);
                dispatch(rfzoTherapyActions.sendAnswerForRFZORequestFailed())
            })
    };
}

export const fetchRFZOUpcomingUsers = (dateFrom: string, dateTo: string, id_branch_marketplace: number = 0, id_marketplace: number = 0): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(rfzoTherapyActions.fetchRFZOUpcomingUsersStart());
        let URL = "/rfzo_therapy_reminder/with_due_date"
        if (id_branch_marketplace === 0 || id_marketplace === 0) {
            URL = URL + "?from=" + dateFrom + "&to=" + dateTo;
        } else {
            URL = URL + "/branch_marketplace_id/" + id_branch_marketplace + "/marketplace_id/" + id_marketplace + "?from=" + dateFrom + "&to=" + dateTo;
        }
        axios.get(URL)
            .then(response => {
                dispatch(rfzoTherapyActions.fetchRFZOUpcomingUsersSuccess(response.data));
            })
            .catch(error => {
                console.log("Error se desio " + error)
                dispatch(rfzoTherapyActions.fetchRFZOUpcomingUsersFailed())
            })
    }
}

export const fetchRFZOUpcomingArticles = (dateFrom: string, dateTo: string, id_branch_marketplace: number = 0, id_marketplace: number = 0): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(rfzoTherapyActions.fetchRFZOUpcomingArticlesStart());
        let URL = "/rfzo_therapy_reminder/with_due_date/items";
        if (id_branch_marketplace === 0 || id_marketplace === 0) {
            URL = URL + "?from=" + dateFrom + "&to=" + dateTo;
        } else {
            URL = URL + "/branch_marketplace_id/" + id_branch_marketplace + "/marketplace_id/" + id_marketplace + "?from=" + dateFrom + "&to=" + dateTo;
        }
        axios.get(URL)
            .then(response => {
                dispatch(rfzoTherapyActions.fetchRFZOUpcomingArticlesSuccess(response.data));
            })
            .catch(error => {
                console.log("Error se desio " + error)
                dispatch(rfzoTherapyActions.fetchRFZOUpcomingArticlesFailed())
            })
    }
}