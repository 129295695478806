import React from 'react';
import OurPatients from "../../components/OurPatientsComponents/OurPatients";
import styles from './OurPatientsPage.module.scss'

const OurPatientsPage = () => {
    return (
        <div className={styles.OurPatientsPageWrapper}>
            <h2>Korisnici koji su dodali ovu apoteku kao omiljenu</h2>
            <OurPatients />
        </div>
    );
};

export default OurPatientsPage;