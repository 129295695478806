import React from "react";
import styles from "./PrescriptionPhoto.module.scss";

type Props = {
  prescriptionLink: any;
};

const PrescriptionPhoto = ({ prescriptionLink }: Props) => {
  return (
    <div className={styles.PrescriptionPhoto}>
      <a
        rel="noreferrer"
        target="_blank"
        className={styles.linkWrap}
        href={process.env.REACT_APP_CDN_PATH + "prescriptions_images/" + prescriptionLink.imageLink}>
        <img
          alt="img"
          className={styles.image}
          src={process.env.REACT_APP_CDN_PATH + "prescriptions_images/" + prescriptionLink.imageLink}
        />
      </a>
    </div>
  );
};

export default PrescriptionPhoto;
