import React from 'react';
import styles from './ArticleItem.module.scss';
import {ReactComponent as XIcon} from './../../assets/icons/trashIcon.svg'
import {RFZOArticle, rfzoTherapyActions} from "../../store/rfzoTherapy-slice";
import {useDispatch} from "react-redux";

type Props = {
    article: RFZOArticle,
    xIcon?: boolean
}

const ArticleItem = ({article, xIcon}:Props) => {

    const dispatch = useDispatch();

    const removeArticle =()=> {
        dispatch(rfzoTherapyActions.removeArticleFromRFZORequest(article.itemId))
    }

    return (
        <div>
            <div className={styles.ArticleItem}>
                <span className={styles.ArticleName}>{article.articleName}</span>
                <div className={styles.ArticleData}>
                    <span>
                    {article.rfzoQuantity}x {article?.rfzoParticipationPrice?.toFixed(2)} RSD
                    </span>
                    {xIcon &&
                        <span onClick={removeArticle}><XIcon className={styles.TrashIcon}/></span>
                    }
                </div>
            </div>

        </div>
    );
};

export default ArticleItem;