import React from 'react';
import styles from './DataTable.module.scss';

type Props = {
    headers: String[],
    dataRows: JSON[] | null,
}

const DataTable = ({headers, dataRows}: Props) => {
    return (
        <div className={styles.TableWrapper}>
            <table>
                <tbody>
                    <tr>
                        {headers.map((headerName, index) => {
                            return (
                                <th key={index}>{headerName}</th>
                            )
                        })}
                    </tr>

                    {dataRows?.map((singleRow, index) => {
                        return (
                            <tr key={index}>
                                {
                                    Object.values(singleRow).map((dataCell, index) => {
                                        return (
                                            <td key={index}>{dataCell && Object.values(dataCell.toString())}</td>
                                        );
                                    })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default DataTable;