import React from 'react';
import styles from "./ColumnComponent.module.scss";

type Props = {
    children?: React.ReactNode;
    leftSideLabel:string;
    leftSideNumber:string;
    rightSide?: any;
    style?: React.CSSProperties;
    bodyStyle?: React.CSSProperties;
};

const ColumnComponent = ({leftSideLabel, leftSideNumber, rightSide, children, style, bodyStyle}: Props) => {
    return (
        <div className={styles.ColumnComponent} style={style}>
            <div className={styles.Header}>
                <span style={{fontSize:"36px", color: "#FFF", display:"flex"}}><strong> &nbsp;{leftSideNumber}</strong></span>
                {rightSide}
                <div className={styles.leftSideLabel}>
                {leftSideLabel}
                </div>
            </div>
            <div className={styles.Body} style={bodyStyle}>
                {children}
            </div>
        </div>
    );
};

export default ColumnComponent;
