import React, { useEffect, useRef, useState } from "react";
import ColumnComponent from "../../ColumnComponent/ColumnComponent";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import OrderBox from "../../OrderBoxV2/OrderBoxV2";
import PerfectScrollbar from "react-perfect-scrollbar";
import orderReceivedSound from "../../../../assets/sounds/jingle.wav";
import OrderDriverBox from "../../OrderDriverBox/OrderDriverBox";

type Props = {
  isAdmin?: boolean;
  isDriver?: boolean;
};

const PendingColumnDriver = ({ isAdmin, isDriver }: Props) => {
  const orderState = useSelector((state: RootState) => state.order);

  const [orders, setOrders] = useState<any>(<div>Waiting...</div>);

  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout>();
  const audioRef = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio(orderReceivedSound);
      audioRef.current.volume = 0.1;
      audioRef.current.loop = false;
    }

    if (isPlaying && audioRef.current) {
      audioRef.current.play();
      intervalRef.current = setInterval(() => {
        if (audioRef.current) {
          audioRef.current.currentTime = 0;
          audioRef.current.play();
        }
      }, 30000);
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isPlaying, orderReceivedSound]);

  useEffect(() => {
    console.log(orderState)
    if (orderState.receivedOrders.length > 0) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [orderState, setIsPlaying]);

  useEffect(() => {
    if (!orderState.loadingOrders) {
      if (orderState.ordersFailed) {
        //     tODO ovde prikaz ako se desila greska prilikom ucitavanja porudzbina
      } else {
        setOrders(
          orderState.receivedOrders.length > 0 &&
            orderState.receivedOrders.map((order: any, index: number) => { 
              return isDriver ? <OrderDriverBox  key={index} order={order}/> : <OrderBox key={index} order={order} isAdmin={isAdmin} isDriver={isDriver} />;
            })
        );
      }
    }
  }, [orderState.receivedOrders, orderState.loadingOrders]);


  return (
    <ColumnComponent
      leftSideLabel="Za prihvatanje"
      leftSideNumber={orderState.receivedOrders.length.toString()}
      // rightSide={rightSideContent}
      style={{ flex: "4" }}>
      <PerfectScrollbar style={{ padding: "4px" }}>
        {orders}
        {/* {rfzoRequest} */}
      </PerfectScrollbar>
    </ColumnComponent>
  );
};

export default PendingColumnDriver;
