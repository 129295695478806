import React, {useEffect, useState} from 'react';
import ColumnComponent from "../../ColumnComponent/ColumnComponent";
import OrderBox from "../../OrderBoxV2/OrderBoxV2";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import PerfectScrollbar from "react-perfect-scrollbar";
import OrderDriverBox from '../../OrderDriverBox/OrderDriverBox';

type Props = {
    isAdmin?: boolean,
    isDriver?: boolean,
    title?: string
}
const ProcessedColumn =  ({isAdmin, isDriver, title}: Props) => {

    const orderState = useSelector((state:RootState) => state.order);

    const [orders, setOrders] = useState<any>(<div>Waiting...</div>)

    useEffect(()=>{
        if(!orderState.loadingOrders) {
            if (orderState.ordersFailed) {
                //     tODO ovde prikaz ako se desila greska prilikom ucitavanja porudzbina
            } else {
                setOrders(orderState.processedOrders.length > 0 && orderState.processedOrders.map((order:any, index:number) => {
                    return(
                        isDriver ? 
                            <OrderDriverBox processed={true} key={order.id} order={order} /> :
                            <OrderBox processed={true} isDriver={isDriver} key={order.id} order={order} isAdmin={isAdmin}/>
                    );
                }))
            }
        }
    }, [orderState.processedOrders, orderState.loadingOrders])
    return (
        <ColumnComponent leftSideLabel={title || ""} leftSideNumber={orderState.processedOrders.length.toString()} style={{flex:"4"}}>
            <PerfectScrollbar style={{padding:"4px"}}>
                {orders}
            </PerfectScrollbar>
        </ColumnComponent>
    );
};

export default ProcessedColumn;
