import React from 'react';
import styles from './ChatBubble.module.scss';

type Props = {
    children?: React.ReactNode,
    noteFromUserFlag?: boolean,
    order?: any
}
const ChatBubble = ({children, noteFromUserFlag, order}: Props)  => {
    return (
        <div className={styles.Message}>
            {(order && order.noteForUser && order.noteForCanceledStatus) ?
                <>
                {order.noteForUser && <p className={`${styles.FromPharmacy} ${styles.NoTail}`}>{order.noteForUser}</p>}
                {order.noteForCanceledStatus && <p className={styles.FromPharmacy}>{children}</p>}
                </>
            :
            <p className={noteFromUserFlag ? styles.FromUser: styles.FromPharmacy}>
                {children}
            </p>
            }
        </div>
    );
};

export default ChatBubble;