
import React, { createContext } from 'react'
import axios_apoteka, {baseUrl} from "./../../axios/axios";
import axios from 'axios';

import {useDispatch} from "react-redux";
import {orderActions} from "../order-slice";
import {fetchNewOrder, saveOrderPOSError, setSentToPosFlag} from "../order-actions";

const OrderSocketContext = createContext({
    connect: () => {},
    sendMessage: (msg:any)=>{},
    closeConnection: ()=>{},
    tickleConnection: () => {}
})

export { OrderSocketContext }

type Props = {
    children?: React.ReactNode
}

const OrderSocket = ({children}:Props) => {
    let stompClient:any = null;
    let connected = false;


    const dispatch = useDispatch();

    const connect = () => {
        dispatch(orderActions.orderSocketConnectionStart())
        const Stomp = require("stompjs");
        let SockJS = require("sockjs-client");
        SockJS = new SockJS(baseUrl + "/ws-order-communication");
        stompClient = Stomp.over(SockJS);
        stompClient.connect({}, afterConnect, onConnectionClosed);
    };

    const closeConnection = () => {
        if (stompClient) {
            stompClient.disconnect();
            dispatch(orderActions.orderSocketConnectionChanged(false))
        }
        connected = false;
    }

    const onConnectionClosed = () => {
        console.log("Konekcija ws-a zatvorena!")
        dispatch(orderActions.orderSocketConnectionChanged(false))
        connected = false;
    }

    const tickleConnection = () => {
        connected ? closeConnection() : connect();
    }

    const afterConnect = () => {
        connected = true;
        dispatch(orderActions.orderSocketConnectionChanged(true))
        stompClient.subscribe(
            "/user/" + localStorage.getItem('email') + "/queue/messages",
            // "/user/admin@gmail.com/queue/messages",
            onMessageReceived
        );
    };

    const onError = (err:JSON) => {
        connected = false;
        console.log(err);
    };

    const onMessageReceived = (msg:any) => {
        const message = JSON.parse(msg.body);
        // const active = JSON.parse(sessionStorage.getItem("recoil-persist"))
        //     .chatActiveContact;

        if (message.type === 'ORDER_REQUEST') {
            dispatch<any>(fetchNewOrder(message.content.cncOrderId));
            dispatch(orderActions.newOrderDesktopNotification(true))

            if (localStorage.getItem("boIpAddress") != null) {
                const URL = '/galen_job/' + message.content.cncOrderId;
                axios_apoteka.get(URL)
                    .then(response => {
                        console.log(response.data);
                        const config = {
                            headers: { Authorization: "Bearer " + localStorage.getItem('token') }
                        };
                        axios.post( localStorage.getItem("boIpAddress") + "/order/create", [{...response.data}], config)
                            .then(response =>  {
                                console.log(response);
                                dispatch<any>(setSentToPosFlag([{orderPOSId: response.data[0], orderId: message.content.cncOrderId}]))
                            })
                            .catch(error => {
                                let responseData = {}
                                if (error.response !== undefined) {
                                    responseData = {
                                        data: JSON.stringify(error.response.data),
                                        status: '' + error.response.status + error.response.statusText,
                                        headers: JSON.stringify(error.response.headers)
                                    }
                                }
                                dispatch<any>(saveOrderPOSError([{
                                    error: JSON.stringify(error),
                                    ...responseData,
                                    orderId: +message.content.cncOrderId
                                }]));
                            })

                    })
                    .catch(error => {
                        console.log("Error se desio" + error);
                    })
            }

            console.log(message)
        } else {
            console.log("Received a new message from " + message.senderName);
            // message.info("Received a new message from " + message.senderName);
        }
    };

    const sendMessage = (msg:any) => {
        stompClient.send("/apoteka-rs-socket/order", {}, JSON.stringify(msg));
    };

    return (
        <OrderSocketContext.Provider value={{connect:connect, sendMessage:sendMessage, closeConnection:closeConnection, tickleConnection: tickleConnection}}>
            {children}
        </OrderSocketContext.Provider>
    )
}

export default OrderSocket;
