import React from 'react';
import PushNotifications from "../../components/PushNotificationsComponents/PushNotifications";
import styles from './PushNotificationsPage.module.scss';

const PushNotificationsPage = () => {
    return (
        <div className={styles.PushNotificationsPageWrapper}>
            <h2>Slanje push notifikacija korisnicima mobilnih aplikacija</h2>
        <PushNotifications />
        </div>
    );
};

export default PushNotificationsPage;