import React, { useEffect, useState } from "react";
import SimpleModal from "../SimpleModal/SimpleModal";
import { useForm } from "react-hook-form";
import styles from "./ReminderModal.module.scss";
import Button from "../../Buttons/Button/Button";
import axios from "axios";
import { baseUrl } from "../../../../axios/axios";
import { fetchItemNames } from "../../../../store/rfzoTherapy-actions";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../RFZOTherapyRequestModal/SearchWrapper/SearchBar/SearchBar";
import { RootState } from "../../../../store";
import RFZOArticleModal from "./RFZOArticleModal/RFZOArticleModal";
import ArticleItem from "./ArticleItem/ArticleItem";

function ReminderModal({ show, backdropClickHandler, isNeW, data, onSubmit }: any) {
  const [formData, setFormData] = useState<any>(data);

  const [fieldError, setFieldError] = useState<any>([]);

  const onChange = (key: string, value: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };
  const dispatch = useDispatch();

  const itemNames = useSelector((state: RootState) => state.rfzoTherapy.itemNames);

  useEffect(() => {
    validationFunction(formData);
  }, [formData]);

  useEffect(() => {
    dispatch<any>(fetchItemNames());
  }, []);

  const [error, setError] = useState(false);

  const inputFields = [
    {
      name: "firstName",
      type: "string",
      disabled: data?.id,
      label: "Ime",
    },
    {
      name: "secondName",
      type: "string",
      disabled: data?.id,
      label: "Prezime",
    },
    {
      name: "lbo",
      type: "string",
      disabled: false,
      label: "LBO",
      validation: /^\d{11}$/,
    },
    {
      name: "phoneNumber",
      type: "string",
      disabled: false,
      label: "Broj telefona",
    },
    {
      name: "username",
      type: "string",
      disabled: data?.id,
      label: "Email",
    },
    {
      name: "date",
      type: "date",
      disabled: false,
      label: "Datum",
    },
    {
      name: "dueDate",
      type: "month",
      disabled: false,
      label: "Poslednja terapija",
    },
  ];

  useEffect(() => {
    setFormData({
      id: localStorage.getItem("id"),
      secondName: data.secondName,
      firstName: data.firstName,
      phoneNumber: data.phoneNumber,
      lbo: data.lbo,
      date: data.date,
      username: data.username,
      dueDate: data.dueDate,
      note: data.note,
    });
  }, [data]);

  const validationFunction = (formData: any) => {
    let errorsArray: Array<any> = [];
    inputFields.forEach((field) => {
      if (field.validation && !field.validation.test(formData[field.name])) {
        errorsArray.push(field.name);
      }
    });
    setFieldError(errorsArray);
  };

  const createReminder = (e: any, formData: any) => {
    e.stopPropagation();
    e.preventDefault();

    const remappedData = {
      willBeReady: formData.date,
      lastTherapyDate: formData.dueDate + "-28",
      branchMarketplaceId: localStorage.getItem("branchMarketplaceId"),
      marketplaceId: localStorage.getItem("marketplaceId"),
      therapyRequest: {
        userLbo: formData.lbo,
        userFirstName: formData.firstName,
        userSecondName: formData.secondName,
        userEmail: formData.username,
        userAuthorizedId: formData.id,
      },
      note: formData.note,
      userPhone: formData.phoneNumber,
      items: articlesList,
    };

    axios
      .post(baseUrl + "/rfzo_therapy_reminder", remappedData, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
      .then(() => {
        backdropClickHandler();
        onSubmit();
      })
      .catch((err) => setError(true));
    console.log(formData);
    return;
  };

  const shouldDisableSubmit = hasFalsyField(formData) || fieldError.length > 0;

  const [articlesList, setArticlesList] = React.useState<any[]>([]);

  const [currentArticle, setCurrentArticle] = React.useState(null);

  const onSubmitArticle = (article: any) => {
    setArticlesList((prev) => [...prev, article]);
    setCurrentArticle(null);
  };

  const customClickHandler = (val: any) => {
    setCurrentArticle(val);
    console.log(val);
  };

  const removeItemFromArticleList = (article: any) => {
    setArticlesList((prev) => {
      const temp = prev.filter((i) => i.itemId != article.itemId);
      return temp;
    });
  };

  return (
    <SimpleModal backdropClickHandler={backdropClickHandler} show={show}>
      {currentArticle && (
        <RFZOArticleModal onSubmitArticle={onSubmitArticle} show={!!currentArticle} selectedItem={currentArticle} />
      )}

      <form className={styles.formWrap}>
        <div style={{ width: "100%" }}>
          <label>Izaberite proizvode</label>
          <SearchBar customClickHandler={customClickHandler} data={itemNames} />

          {articlesList.map((article: any, key: number) => (
            <ArticleItem xIcon removeArticle={removeItemFromArticleList} article={article} key={key} />
          ))}
        </div>
        {inputFields.map((field) => (
          <div className={styles.fieldWrap}>
            <label>{field.label}</label>
            <input
              style={{
                border: `1px solid ${field.validation && !field.validation?.test(formData[field.name]) ? "red" : ""}`,
              }}
              {...field}
              value={formData[field.name]}
              onChange={(e) => onChange(field.name, e.target.value)}
            />
          </div>
        ))}
        <div className={styles.fieldWrap}>
          <label>Napomena farmaceuta</label>
          <textarea style={{width: "100%"}} onChange={(e) => onChange("note", e.target.value)} value={formData.note} />
        </div>
        <div style={{ position: "sticky", bottom: "0px" }}>
          <Button
            disabled={shouldDisableSubmit}
            buttonType="blue"
            type="submit"
            onClick={(e: any) => createReminder(e, formData)}>
            Kreiraj podsetnik
          </Button>
          {error && <div className={styles.errorDiv}>Doslo je greške</div>}
        </div>
      </form>
    </SimpleModal>
  );
}

export default ReminderModal;

function hasFalsyField(obj: any) {
  console.log(obj);
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && !obj[key] && key !== "id") {
      return true;
    }
  }
  return false;
}
