import React from 'react';
import DataTable from "../../../components/UI/DataTable/DataTable";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

const SearchInputTable = () => {

    const unprocessedSearchInputs:any = useSelector((state:RootState) => state.searchInput.unprocessedSearchInputs);

    return (
        <DataTable headers={["Redni broj", "Datum upita", "Pretraženi tekst", "Ime i Prezime", "E-mail", "Broj telefona", "Status"]} dataRows={unprocessedSearchInputs}/>
    );
};

export default SearchInputTable;
