import React, { useEffect, useState } from "react";
import PrescriptionPhoto from "./PrescriptionPhoto/PrescriptionPhoto";
import styles from "./PrescriptionPhotos.module.scss";

type Props = {
  prescriptionsLinks: any;
  permitForStore?: any;
};

const PrescriptionPhotos = ({ prescriptionsLinks, permitForStore }: Props) => {
  const [photos, setPhotos] = useState<any>();

  useEffect(() => {
    if (prescriptionsLinks) {
      let tempArr = prescriptionsLinks.map((link: string, index: number) => {
        return <PrescriptionPhoto key={index} prescriptionLink={link} />;
      });
      setPhotos(tempArr);
    }
  }, [prescriptionsLinks]);

  return (
    (prescriptionsLinks?.length > 0 || permitForStore) && (
      <div className={styles.PrescriptionPhotos}>
        {photos}
        {permitForStore && (
          <a className={styles.permitWrap} target="_blank" rel="noreferrer" href={permitForStore}>
            Punomoćje
          </a>
        )}
      </div>
    )
  );
};

export default PrescriptionPhotos;
