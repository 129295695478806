import React, {ComponentProps} from 'react';
import styles from './PrintOrderDetails.module.scss';
import Logo from "../../../UI/Logo/LogoBW";

interface Props extends ComponentProps<any> {

}

export type Ref = HTMLDivElement;

const PrintOrderInfo = React.forwardRef<Ref, Props>((props, ref) => {

    let date = new Date('' + props.order.created);

    const checkFormat = (data: string) => {
        return data.length < 2 ? "0" + data : data;
    }

    return (
        <div ref={ref} className={styles.PrintWrapper}>
            <Logo/>
            <div style={{fontWeight: "bold"}}>{props.order.firstName} {props.order.secondName}</div>
            <div style={{fontWeight: "bold"}}>#{props.order.id}</div>

            {/*<span><strong>{checkFormat(String(date.getHours())) + ':' + checkFormat(String(date.getMinutes()))}</strong></span>*/}
            <span>{checkFormat(String(date.getDate())) + '.' + (checkFormat(String(date.getMonth() + 1))) + '.' + date.getFullYear() + '.'}</span>

            {props.order.items.map((item: any, index:number) => {
                return (<div key={index}>
                        <span className={styles.ArticleName}><strong>{item.itemResponse.name}</strong></span>
                        <span>količina: {item.quantity}</span>
                        <div style={{width:"100%"}}>
                            <input type="text" value="Doziranje" readOnly/>
                            <input type="text" value="Trajanje" readOnly/>
                            <textarea value="Napomena" style={{height:"80px"}} readOnly/>
                            <br/>
                        </div>
                    </div>
                );
            })}
        </div>
    )
})
    ;

    export default PrintOrderInfo;