import React, { useEffect, useState } from "react";
import ColumnComponent from "../../ColumnComponent/ColumnComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import OrderBox from "../../OrderBoxV2/OrderBoxV2";
import PerfectScrollbar from "react-perfect-scrollbar";
import OrderDriverBox from "../../OrderDriverBox/OrderDriverBox";

type Props = {
  isAdmin?: boolean;
};
const DriverArchivedColumn = ({ isAdmin }: Props) => {
  const orderState = useSelector((state: RootState) => state.order);
  const [orders, setOrders] = useState<any>(<div>Waiting...</div>);
  useEffect(() => {
    if (!orderState.loadingOrders) {
      if (orderState.ordersFailed) {
        //     tODO ovde prikaz ako se desila greska prilikom ucitavanja porudzbina
      } else {
        setOrders(
          orderState.finishedOrders.length > 0 &&
            orderState.finishedOrders.map((order: any, index: number) => {
              return <OrderDriverBox archived={true} key={index} order={order} />;
            })
        );
      }
    }
  }, [orderState.finishedOrders, orderState.loadingOrders]);

  return (
    <ColumnComponent
      leftSideLabel="Obrađene"
      leftSideNumber={orderState.finishedOrders.length.toString()}
      bodyStyle={{ backgroundColor: "transparent", border: "none" }}
      style={{ flex: "4" }}>
      <PerfectScrollbar style={{ padding: "4px" }}>{orders}</PerfectScrollbar>
    </ColumnComponent>
  );
};

export default DriverArchivedColumn;
