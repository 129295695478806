import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {fetchUsersSearchInputs} from "../../store/search-input-actions";
import SearchInputTable from "./SearchInputTable/SearchInputTable";

const PharmacistSupportPage = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch<any>(fetchUsersSearchInputs())
    }, [])

    return (
        <>
            <SearchInputTable/>
        </>
    );
};

export default PharmacistSupportPage;