import {configureStore} from "@reduxjs/toolkit";

import orderSlice from './order-slice';
import authSlice from "./auth-slice";
import rfzoTherapySlice from "./rfzoTherapy-slice";
import statusSlice from "./status-slice";
import searchInputSlice from "./search-input-slice";
import adminCmsSlice from "./admin-cms-slice";
import patientsSlice from "./patients-slice";

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        order: orderSlice.reducer,
        rfzoTherapy: rfzoTherapySlice.reducer,
        statusSlice: statusSlice.reducer,
        searchInput: searchInputSlice.reducer,
        adminCMS: adminCmsSlice.reducer,
        patients: patientsSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
