import React from "react";
import { Progress } from "rsuite";
import styles from "./ProgressBar.module.scss";
import moment from "moment";
type Props = {
  intervalType: "m" | "h";
  intervalLength: number;
  startPoint: Date;
};

function ProgressBar({ intervalType, intervalLength, startPoint }: Props) {
  let percentage = 0;
  let value = 0;
  const currentTime = moment();
  const startPointTime = moment(startPoint);

  if (intervalType === "m") {
    value = currentTime.diff(startPointTime, "m");
    percentage = (value / 10) * 100;
  } else if (intervalType === "h") {
    value = currentTime.diff(startPointTime, "h");
    percentage = (value / 48) * 100;
  }

  return (
    <div className={styles.progressBarWrap}>
      <Progress.Circle
        style={{
          height: "54px",
          width: "54px",
        }}
        showInfo={false}
        percent={percentage}
        strokeWidth={8}
        strokeColor="#00B2B1"
      />
      <div className={styles.barValue}>
        {value}
        {intervalType}
      </div>
    </div>
  );
}

export default ProgressBar;
