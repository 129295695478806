import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    statusScreenVisible:false
}

const statusSlice = createSlice({
    name: "statusSlice",
    initialState: initialState,
    reducers: {
        toggleStatusScreen: (state, action) => {
            state.statusScreenVisible = action.payload;
        }
    }

});

export const statusActions = statusSlice.actions;
export default statusSlice;