import React, {useMemo} from 'react';
// @ts-ignore
import {useTable, useSortBy, useFilters} from 'react-table';
import {useDispatch} from "react-redux";
import {changeArticleOutletValue} from "../../../../store/admin-cms-actions";
import styles from '../../ArticlesTable.module.scss';
import ColumnFilter from "../../ColumnFilter";

type Props = {
    articlesData: [],
    marketplaceId: number,
    branchMarketplaceId: number
}

const ArticlesTableForSingleMarketplace = ({articlesData, branchMarketplaceId, marketplaceId}: Props) => {

    const dispatch = useDispatch();

    const data = useMemo(() => {

        return articlesData.map((article: any) => {

            const changeOutletStatus = (e: any) => {
                dispatch<any>(changeArticleOutletValue([{
                    branchMarketplaceId: +branchMarketplaceId,
                    marketplaceId: marketplaceId,
                    itemId: article.itemResponse.id,
                    outletMarket: e.target.checked
                }]))
            }
            return (
                {
                    col1: article.itemResponse.id,
                    col2: article.itemResponse.name,
                    col3: article.itemPrice.toFixed(2),
                    col4: article.itemPriceWithDiscount && article.itemPriceWithDiscount.toFixed(2),
                    col5: article.itemQuantity,
                    col6: <input type="checkbox" defaultChecked={article.outletMarket} onChange={e => changeOutletStatus(e)}
                                   style={{backgroundColor: "red"}}/>,
                    col7: article.outletMarket ? 'outlet' : ''
                }
            )
        })
    },[articlesData])

    const columns = React.useMemo(
        () => [
            {
                Header: 'id',
                accessor: 'col1', // accessor is the "key" in the data
                Filter: ColumnFilter
            },
            {
                Header: 'Naziv artikla',
                accessor: 'col2',
                Filter: ColumnFilter
            },
            {
                Header: "Cena",
                accessor: 'col3',
                Filter: false
            },
            {
                Header: "Cena sa popustom",
                accessor: 'col4',
                Filter: false
            },
            {
                Header: "Količina",
                accessor: 'col5',
                Filter: ColumnFilter
            },
            {
                Header: "Promeni outlet",
                accessor: 'col6',
                Filter: false
            },
            {
                Header: "Outlet",
                accessor: 'col7',
                Filter: ColumnFilter
            },
        ],
        []
    )

    const tableInstance = useTable({columns, data}, useFilters, useSortBy)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    return (
        // apply the table props
        <table {...getTableProps()} style={{border: 'solid 1px blue'}}>
            <thead>
            {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            <div style={{display:"flex", justifyContent:"center"}}>{column.render('Header')}
                                <span>
                        {column.isSorted
                            ? (column.isSortedDesc
                                ? ' ▼'
                                : ' ▲')
                            : ''}
                        </span>
                            </div>
                            <div>{column.canFilter ? column.render('Filter') : null}</div>

                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} className={styles.Tr}>
                        {row.cells.map((cell: any) => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                >
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
};

export default ArticlesTableForSingleMarketplace;
