import React, {createContext} from 'react'
import axios_apoteka, {baseUrl} from "./../../axios/axios";
import {useDispatch} from "react-redux";
import axios from "axios";
import {rfzoTherapyActions} from "../rfzoTherapy-slice";
import {changeRFZOTherapyStatus, fetchNewRFZOTherapy} from "../rfzoTherapy-actions";


const RFZOTherapySocketContext = createContext({
    connect: () => {},
    isConnected: () => {},
    sendMessage: (msg:any) => {},
    closeConnection: () => {},
    tickleConnection: () => {}
})

export { RFZOTherapySocketContext }

type Props = {
    children?: React.ReactNode
}

const RFZOTherapySocket = ({children}:Props) => {
    let stompClient:any = null;
    let connected = false;

    const dispatch = useDispatch();

    const connect = () => {
        dispatch<any>(rfzoTherapyActions.rfzoSocketConnectionStart());
        const Stomp = require("stompjs");
        let SockJS = require("sockjs-client");
        SockJS = new SockJS(baseUrl + "/ws-rfzo-therapy-communication"/* + "?access_token="+localStorage.getItem('token')*/);
        stompClient = Stomp.over(SockJS);
        stompClient.connect({}, afterConnect, onConnectionClosed);
    };

    const closeConnection = () => {
        if (stompClient) {
            stompClient.disconnect();
            dispatch(rfzoTherapyActions.rfzoSocketConnectionChanged(false));
        }
        connected = false;
    }

    const onConnectionClosed = () => {
        console.log("Konekcija rfzo therapy ws-a zatvorena!")
        connected = false;
        dispatch(rfzoTherapyActions.rfzoSocketConnectionChanged(false));
    }

    const tickleConnection = () => {
        connected ? closeConnection() : connect();
    }

    const afterConnect = () => {
        connected = true;
        dispatch(rfzoTherapyActions.rfzoSocketConnectionChanged(true));
        stompClient.subscribe(
            "/user/" + localStorage.getItem('email') + "/queue/messages",
            onMessageReceived
        );
    };

    const onError = (err:JSON) => {
        connected = false;
        console.log(err);
    };

    const isConnected = () => {
        return connected
    }

    const onMessageReceived = (msg:any) => {

        const message = JSON.parse(msg.body);
        // const active = JSON.parse(sessionStorage.getItem("recoil-persist"))
        //     .chatActiveContact;

        switch (message.type) {
            case 'RFZO_THERAPY_REQUEST':
                dispatch<any>(fetchNewRFZOTherapy(message.content.rfzoTherapyId));
                dispatch(rfzoTherapyActions.newRFZORequestDesktopNotification(true));

                // if (localStorage.getItem("boIpAddress") != null) {
                //     const URL = '/galen_job/rfzo_therapy/' + message.content.rfzoTherapyId;
                //     axios_apoteka.get(URL)
                //         .then((response:any) => {
                //             console.log(response.data);
                //             const config = {
                //                 headers: { Authorization: "Bearer " + localStorage.getItem('token') }
                //             };
                //             axios.post( localStorage.getItem("boIpAddress") + "/rfzo_therapy/create", {...response.data}, config)
                //                 .then(response =>  {
                //                     console.log(response);
                //                     dispatch<any>(changeRFZOTherapyStatus("REQUEST_SENT_TO_POS", [message.content.rfzoTherapyId]));
                //                 })
                //                 .catch(error => {
                //                     // let responseData = {}
                //                     // if (error.response !== undefined) {
                //                     //     responseData = {
                //                     //         data: JSON.stringify(error.response.data),
                //                     //         status: '' + error.response.status + error.response.statusText,
                //                     //         headers: JSON.stringify(error.response.headers)
                //                     //     }
                //                     // }
                //                     // props.onSendOrderPOSError([{
                //                     //     error: JSON.stringify(error),
                //                     //     ...responseData,
                //                     //     orderId: +message.content.cncOrderId
                //                     // }]);
                //                 })
                //
                //         })
                //         .catch((error:any) => {
                //             console.log("Error se desio" + error);
                //         })
                // }
                break;
            case 'RFZO_THERAPY_ACK':

                break;
            case 'RFZO_THERAPY_REJECTED': case 'RFZO_THERAPY_EXPIRED':
                // if (localStorage.getItem("boIpAddress") != null) {
                //     const config = {
                //         headers: { Authorization: "Bearer " + localStorage.getItem('token') }
                //     };
                //     axios.post( localStorage.getItem("boIpAddress") + "/rfzo_therapy/cancel/" + message.content.rfzoTherapyId, null, config)
                //         .then(response =>  {
                //             console.log(response);
                //             dispatch<any>(changeRFZOTherapyStatus("REJECT_REQUEST_SENT_TO_POS", [message.content.rfzoTherapyId]));
                //         })
                //         .catch(error => {
                //             // let responseData = {}
                //             // if (error.response !== undefined) {
                //             //     responseData = {
                //             //         data: JSON.stringify(error.response.data),
                //             //         status: '' + error.response.status + error.response.statusText,
                //             //         headers: JSON.stringify(error.response.headers)
                //             //     }
                //             // }
                //             // props.onSendOrderPOSError([{
                //             //     error: JSON.stringify(error),
                //             //     ...responseData,
                //             //     orderId: +message.content.cncOrderId
                //             // }]);
                //         })
                //
                // }
                break;
            case 'RFZO_THERAPY_DELETED':
                dispatch(rfzoTherapyActions.rfzoTherapyDeleted(message.content.rfzoTherapyId));
                break;
            case 'RFZO_THERAPY_REQUEST_EXPIRED':
                dispatch(rfzoTherapyActions.rfzoTherapyDeleted(message.content.rfzoTherapyId));
                break;
        }
    };

    const sendMessage = (msg:any) => {
        stompClient.send("/apoteka-rs-socket/therapy", {}, JSON.stringify(msg));
    };

    const toReturn = {
        connect,
        isConnected,
        sendMessage,
        closeConnection,
        tickleConnection
    }


    return (
        <RFZOTherapySocketContext.Provider value={{connect:connect, isConnected:isConnected, sendMessage:sendMessage, closeConnection:closeConnection, tickleConnection:tickleConnection}}>
            {children}
        </RFZOTherapySocketContext.Provider>
    )
}

export default RFZOTherapySocket;
