import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import axios from "../axios/axios";
import {searchInputActions} from "./search-input-slice";

export const fetchUsersSearchInputs = (): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(searchInputActions.fetchUserSearchInputsStart());
        const URL = '/user_search_input';
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(searchInputActions.fetchUserSearchInputs(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(searchInputActions.fetchUserSearchInputsFailed())
            })
    };
}