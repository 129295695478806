import React from 'react';
import styles from './ArticlesTable.module.scss'

type Props = {
    column: any
}

const ColumnFilter = ({column}:Props) => {
    const {filterValue, setFilter} = column
    return (
        <span>
            <input value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} className={styles.ColumnFilter}/>
        </span>
    );
};

export default ColumnFilter;