import React from 'react';
import styles from './Button.module.scss'
import type {ComponentPropsWithoutRef} from "react";
import classNames from 'classnames';

interface Props extends ComponentPropsWithoutRef<any> {
    buttonType?: string,
}

const Button = ({buttonType, className, ...rest}:Props) => {

    let button;
    switch (buttonType) {
        case ('blue'):
            button = <button
                className={classNames(styles.BlueButton, className)}
                {...rest}
            />
            break;
        case ('red'):
            button = <button
                className={classNames(styles.RedButton, className)}
                {...rest}
            />
            break;
        case ('black'):
            button = <button
                className={classNames(styles.BlackButton, className)}
                {...rest}
            />
            break;
        case ('white'):
            button = <button
                className={classNames(styles.WhiteButton, className)}
                {...rest}
            />
            break;
        case ('gray'):
            button = <button
                className={classNames(styles.GrayButton, className)}
                {...rest}
            />
            break;
        default:
            button = <button
                className={classNames(styles.Link, className)}
                {...rest}            />
    }

    return (
        <>
            {button}
        </>
    );
};

export default Button;