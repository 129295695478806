import React, { useEffect, useMemo, useState } from "react";
import OurPatientsTable from "./OurPatientsTable/OurPatientsTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchAllPatients } from "../../store/patients-actions";

const OurPatients = () => {
  const dispatch = useDispatch();
  const allPatients = useSelector((state: RootState) => state.patients.allPatients);
  const marketplaceInfo = useSelector((state: RootState) => state.auth.marketplaceInfo);

  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    if (marketplaceInfo) {
      dispatch<any>(fetchAllPatients(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
    }
  }, [marketplaceInfo]);

  const search = (array: Array<any>, searchTerm: string) => {
    if(!array) {
        return []
    }
    return array?.filter(el => (el?.userName?.toLowerCase()?.includes(searchTerm) || el?.firstName?.toLowerCase()?.includes(searchTerm) || el?.secondName?.toLowerCase()?.includes(searchTerm) || el?.lbo?.toLowerCase()?.includes(searchTerm)))
  }


  const patients = useMemo(() => {
    return search(allPatients, searchTerm)
  }, [allPatients, searchTerm])

  return (
    <>
      {allPatients && <div>Broj korisnika: {patients.length}</div>}
      <div style={{width: "100%"}}>
        <label style={{marginBottom: "10px", fontSize: "14px", display: "block"}}>Pretraga</label>
        <input placeholder="Pretražite po imenu ili LBO-u" style={{height: "48px", borderRadius: "15px", width: "50%", padding: "0 20px", border: "1px solid #e9e9e9"}} onChange={e => setSearchTerm(e.target.value.toLowerCase())}/>
      </div>
      <OurPatientsTable usersData={patients} />
    </>
  );
};

export default OurPatients;
