import React, {CSSProperties, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {fetchAllArticlesForBranchMarketplace} from "../../../store/admin-cms-actions";
import {ScaleLoader} from "react-spinners";
import ArticleTableForBranchMarketplace from "./ArticleTableForBranchMarketplace/ArticleTableForBranchMarketplace";

type Props = {
    branchMarketplaceId: number,
}

const ArticlesForBranchMarketplace = ({branchMarketplaceId}: Props) => {
    const dispatch = useDispatch();

    const allArticlesFromBranchMarketplace = useSelector((state: RootState) => state.adminCMS.allArticlesForSingleBranchMarketplace);

    const loadingArticlesFromBranchMarketplace = useSelector((state:RootState) => state.adminCMS.fetchAllArticlesForBranchMarketplaceStart);
    const loadingArticlesFailed = useSelector((state: RootState) => state.adminCMS.fetchAllArticlesForBranchMarketplaceFailed);

    useEffect(() => {
        if (branchMarketplaceId !== -1)
            dispatch<any>(fetchAllArticlesForBranchMarketplace(branchMarketplaceId))
    }, [branchMarketplaceId]);

    const override: CSSProperties = {
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        height:"20vh",
    };

    return (
        <div style={{position:"relative"}}>
            {loadingArticlesFromBranchMarketplace &&
                <ScaleLoader
                    color={"#00B2B1"}
                    loading={loadingArticlesFromBranchMarketplace}
                    cssOverride={override}
                    width={10}
                    radius={5}
                    height={50}
                    aria-label="Učitavanje"
                    data-testid="loader"
                />}

            {allArticlesFromBranchMarketplace.length > 0 && !loadingArticlesFromBranchMarketplace &&
                <ArticleTableForBranchMarketplace articlesData={allArticlesFromBranchMarketplace} branchMarketplaceId={branchMarketplaceId} />
            }

            {loadingArticlesFailed && !loadingArticlesFailed &&
                <div>Greška pri učitavanju podataka.</div>
            }
        </div>
    );
};

export default ArticlesForBranchMarketplace;