import React from 'react';
import styles from './Backdrop.module.scss';

type Props = {
    show: boolean,
    backdropClickHandler?: React.MouseEventHandler;
    zIndex: string | number;
    backgroundColor?: string;
    className?: string
}
const Backdrop = ({show, backdropClickHandler, zIndex, backgroundColor, className}: Props) => {
    return (
        show ? <div className={`${styles.Backdrop} ${className}`}
                    onClick={backdropClickHandler}
                    style={{zIndex: zIndex, backgroundColor: backgroundColor}}/>
            : null
    );
};

export default Backdrop;