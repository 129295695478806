import React, {useEffect, useState} from 'react';
import styles from "./ArticlesOutletPage.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {fetchAllBranchMarketplaces, fetchAllMarketplacesForBranchMarketplace} from "../../store/admin-cms-actions";
import Select from "react-select";
import ArticlesForSingleMarketplace from "../../components/ArticlesComponents/ArticlesForSingleMarketplace/ArticlesForSingleMarketplace";
import {adminCMSActions} from "../../store/admin-cms-slice";

const ArticlesOutletPage = () => {

    const dispatch = useDispatch()
    const branchMarketplacesForSelect = useSelector((state: RootState) => state.adminCMS.branchMarketplaceArrayForSelect)
    const marketplacesForSelect = useSelector((state: RootState) => state.adminCMS.marketplaceArrayForSelect);

    const [branchId, setBranchId] = useState<number>(-1)
    const [marketplaceId, setMarketplaceId] = useState<number>(-1)
    const [showMarketplace, setShowMarketplace] = useState(true)

    useEffect(() => {
        if (branchMarketplacesForSelect.length === 0) {
            dispatch<any>(fetchAllBranchMarketplaces())
        }
    }, []);

    useEffect(() => {
        if (branchId !== -1)
            dispatch<any>(fetchAllMarketplacesForBranchMarketplace(branchId))
    }, [branchId]);

    const takeBranchMarketplaceValue = (e: any) => {
        dispatch(adminCMSActions.clearArticlesFromSingleMarketplace())
        setBranchId(e.value)
    }

    const takeMarketplaceValue = (e: any) => {
        setMarketplaceId(parseInt(e.value))
    }

    useEffect(() => {
        setShowMarketplace(true)
    }, [marketplacesForSelect])

    const closeMarketplaceSelect = () => {
        setShowMarketplace(false)
        setMarketplaceId(-1)
    }

    return (
        <>
            <div className={styles.ArticlesOutletPage}>
                <div className={styles.ComponentHeader}>
                    <Select
                        placeholder={<div>Izaberite pravno lice</div>}
                        options={branchMarketplacesForSelect}
                        openMenuOnClick={true}
                        className={styles.ReactSelect}
                        menuPosition={"fixed"}
                        onChange={takeBranchMarketplaceValue}
                        onFocus={closeMarketplaceSelect}
                    />
                    {showMarketplace && marketplacesForSelect.length > 0 &&
                        <Select
                            placeholder={<div>Izaberite prodajno mesto</div>}
                            options={marketplacesForSelect}
                            openMenuOnClick={true}
                            className={styles.ReactSelect}
                            menuPosition={"fixed"}
                            onChange={takeMarketplaceValue}
                        />
                    }
                </div>

                {branchId !== -1 && marketplaceId !== -1 &&
                    <ArticlesForSingleMarketplace branchMarketplaceId={branchId} marketplaceId={marketplaceId}/>
                }
            </div>
        </>
    );
};

export default ArticlesOutletPage;