import React, { useState } from 'react';
import SimpleModal from "../SimpleModal/SimpleModal";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store";
import {RFZOArticle, rfzoTherapyActions} from "../../../../store/rfzoTherapy-slice";
import {useForm} from "react-hook-form";
import styles from './RFZOArticleModal.module.scss';
import {ReactComponent as XIcon} from "../../../../assets/icons/x-icon.svg";

type Props = {
    show: boolean,
}

const RFZOArticleModal = ({show} :Props) => {

    const dispatch = useDispatch();

    const selectedItem = useSelector((state:RootState) => state.rfzoTherapy.selectedItem);

    const closeModal = () => {
        dispatch(rfzoTherapyActions.toggleAddItemModal(false))
    }
    const { register, handleSubmit, formState: { errors }, setValue} = useForm();

    const onSubmit = (data:any) => {
        let article:RFZOArticle = {
            itemId:selectedItem.id,
            articleName:selectedItem.name,
            rfzoQuantity: data.quantity,
            rfzoParticipationPrice: data.participation || 0
        }

        dispatch(rfzoTherapyActions.addArticleToRFZORequest(article))
        closeModal();
    }

    const [showCustomField, setShowCustomField] = useState(false)
    const selectValue =(e:any)=> {
        e.target.select();
    }

    const dropDownSelect = (e: any) => {
        if(e.target.value == -1) {
            setValue("participation", 0)
            setShowCustomField(true)
        } else {
            setShowCustomField(false)
            setValue("participation", Number.parseFloat(e.target.value))
        }
    }


    return (
        <SimpleModal className={styles.modalClass} show={show} backdropClickHandler={closeModal}>
            <div className={styles.Header}>
                <span>Dodavanje artikla za RFZO</span>
                <XIcon className={styles.XIcon} onClick={closeModal}/>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.FormStyle}>
                <div className={styles.ArticleName}>{selectedItem.name}</div>
                <div className={styles.FormBodyWrapper}>
                    <div className={styles.FormRow}>
                        <label htmlFor="quantity">Količina:</label>
                        <input id="quantity"
                               className={styles.InputField}
                               type="number"
                               step="0.001"
                               min={0}
                               defaultValue={1}
                               onFocus={selectValue}
                               autoFocus={true} {...register("quantity", {required: true, min: 1, valueAsNumber: true})} />
                    </div>
                    <div className={styles.FormRow}>
                        <label htmlFor="participation">Participacija / Učešće:</label>
                               
                        <select
                          className={styles.InputField}
                          onChange={dropDownSelect}>
                            <option value={0}>0.00</option>
                            {selectedItem?.rfzoParticipationPrice && <option value={selectedItem?.rfzoParticipationPrice}>{selectedItem?.rfzoParticipationPrice}</option>}
                            <option value={-1}>Prilagodi</option>
                        </select>
                        {showCustomField && <input id="participation"
                               step=".01"
                               defaultValue={0}
                               className={styles.InputField}
                               type="number" {...register("participation", {required: true, min: 0, valueAsNumber: true})} />}
                    </div>
                </div>
                <input type="submit" value="Dodaj" className={styles.SubmitButton}/>
            </form>
        </SimpleModal>
    );
};

export default RFZOArticleModal;