import React from 'react';
import MobileCommercials from "../../components/CommercialsComponents/MobileCommercials";
import WebCommercials from "../../components/CommercialsComponents/WebCommercials";

type Props = {
    mobile?: boolean,
    web?: boolean,
}

const CommercialsPage = ({mobile, web}: Props) => {
    return (
        <div style={{display: "flex", flexDirection: "column", gap: "12px", margin: "2rem"}}>
            {mobile && <MobileCommercials/>}
            {web && <WebCommercials/>}
        </div>
    );
};

export default CommercialsPage;
