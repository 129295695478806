import React, { useEffect, useState } from "react";
import styles from "./RFZOOrderTherapy.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ChatIcon } from "./../../assets/icons/chat-icon.svg";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import sr from "date-fns/locale/sr-Latn";
import Calendar from "react-calendar";
import { RFZOArticleForMessage, rfzoTherapyActions } from "../../store/rfzoTherapy-slice";
import { RootState } from "../../store";
import { sendAnswerForRFZORequest } from "../../store/rfzoTherapy-actions";
import Button from "../UI/Buttons/Button/Button";
registerLocale("sr", sr);

type Props = {
  rfzoId: number;
  show: boolean;
  backdropClickHandler: () => void;
  hasTherapy?: boolean;
};

export type RFZOMessage = {
  items: RFZOArticleForMessage[];
  willBeReady?: string;
  noteForUser?: string;
  lastTherapyDate?: any;
};

const RFZOOrderTherapy = ({ hasTherapy, backdropClickHandler, rfzoId }: Props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [lastTherapyDate, setLastTherapyDate] = useState<Date | null>(null);
  const [willBeReady, setWillBeReady] = useState<string | null>(null);
  const [textMessage, setTextMessage] = useState<string>("");

  const articlesForRFZO = useSelector((state: RootState) => state.rfzoTherapy.articlesForRFZO);
  const articlesForRFZOMessage = useSelector((state: RootState) => state.rfzoTherapy.articlesForRFZOMessage);
  const sendAnswerForRFZORequestSuccess = useSelector(
    (stete: RootState) => stete.rfzoTherapy.sendAnswerForRFZORequestSuccess
  );

  console.log(articlesForRFZOMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(rfzoTherapyActions.trimRFZOArticlesForMessage());
  }, [articlesForRFZO]);

  useEffect(() => {
    if (startDate) {
      setWillBeReady(
        startDate.getFullYear().toString() +
          "-" +
          (("0" + (startDate.getMonth() + 1).toString()).slice(-2) +
            "-" +
            ("0" + startDate.getDate().toString()).slice(-2))
      );
    } else {
      setWillBeReady(null);
    }
  }, [startDate]);

  const send = () => {
    let rfzoMessage: RFZOMessage = {
      items: articlesForRFZOMessage,
    };
    if (willBeReady) {
      rfzoMessage = { ...rfzoMessage, willBeReady: willBeReady };
    }
    if (textMessage !== "") {
      rfzoMessage = { ...rfzoMessage, noteForUser: textMessage };
    }
    if (lastTherapyDate) {
      rfzoMessage = { ...rfzoMessage, lastTherapyDate: lastTherapyDate };
    }
    dispatch<any>(sendAnswerForRFZORequest(rfzoId, rfzoMessage));
    if (sendAnswerForRFZORequestSuccess) {
      backdropClickHandler();
    }
    // todo if sendAnswerSuccess then call close modal, else display error
  };

  return (
    <div>
      <div className={styles.DatePickerWrapper}>
        <div className={styles.header}>
          {hasTherapy && "Naredna terapija"}
          {!hasTherapy && "Datum dospeća terapije"}
        </div>
        <span>
          {hasTherapy && "Izaberi datum dospeća naredne terapije"}
          {!hasTherapy && "Izaberi datum"}
        </span>
        <div>
          <Calendar
            onChange={(val) => {
              // ts checking if array is sent -_-
              if (Array.isArray(val)) {
                setStartDate(new Date());
              } else {
                setStartDate(val);
              }
            }}
            value={startDate}
            minDate={new Date()}
            locale="sr"
            className={styles.calendarWrap}
          />
        </div>
        {hasTherapy && (
          <span style={{ fontSize: "12px", color: "#00B2B1" }}>
            *Ukoliko je ovo poslednja terapija, nemojte izabrati datum
          </span>
        )}{" "}
        <br />
        <span>Izaberi poslednji mesec dospeća terapije</span>
        <Calendar
          onClickMonth={(val) => {
            // ts checking if array is sent -_-
            console.log(val);
            if (Array.isArray(val)) {
              setLastTherapyDate(new Date());
            } else {
              setLastTherapyDate(val);
            }
          }}
          view="year"
          value={lastTherapyDate}
          minDate={new Date()}
          locale="sr"
          defaultView="year"
          className={styles.calendarWrap}
        />
        {hasTherapy && (
          <span style={{ fontSize: "12px", color: "#00B2B1" }}>
            *Ovo polje farmaceut mora popuniti u toku obrade zahteva
          </span>
        )}
        {hasTherapy && (
          <div id="NoteForUser" className={styles.NoteForUser}>
            <div style={{ width: "90%" }}>
              <textarea
                placeholder="Poruka za korisnika"
                className={styles.TextArea}
                onChange={(e) => setTextMessage(e.target.value)}></textarea>
            </div>
          </div>
        )}
      </div>

      <div className={styles.ModalActions}>
        <Button buttonType="blue" onClick={send}>
          Pošalji odgovor
        </Button>
      </div>
    </div>
  );
};

export default RFZOOrderTherapy;
