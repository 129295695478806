import React, {useEffect, useState} from 'react';
import styles from './StatusComponent.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {statusActions} from "../../store/status-slice";
import {ReactComponent as XIcon} from "../../assets/icons/x-icon.svg";
import Button from "../UI/Buttons/Button/Button";
import {RootState} from "../../store";
import {logoutAction, setCNCActiveStatus} from "../../store/auth-actions";
import {authActions} from "../../store/auth-slice";

type Props = {
    isAdmin?: boolean
    isPharmacist?: boolean,
    isDriver?: boolean
}
const StatusComponent = ({isAdmin, isPharmacist, isDriver}:Props) => {

    const dispatch = useDispatch();
    const marketplaceInfo = useSelector((state:RootState) => state.auth.marketplaceInfo)

    const [activeButtonTitle, setActiveButtonTitle] = useState('Status: aktivna')

    useEffect(() => {
        if(!isAdmin && !isPharmacist) {
            if (marketplaceInfo && marketplaceInfo?.takesOrders === 1) {
                setActiveButtonTitle('Status: aktivna')
            } else {
                setActiveButtonTitle('Status: neaktivna')
            }
        }
    }, [marketplaceInfo]);

    const closeStatusScreen = () => {
        dispatch(statusActions.toggleStatusScreen(false));
    }

    const logout = () => {
        dispatch<any>(logoutAction(marketplaceInfo?.branchMarketplaceId, marketplaceInfo?.marketplaceId, 0))
        setTimeout(() => {
            dispatch(statusActions.toggleStatusScreen(false));
        }, 800);
    }

    const logoutAdmin = () => {
        dispatch(authActions.logoutUser());
        setTimeout(() => {
            dispatch(statusActions.toggleStatusScreen(false));
        }, 800);
    }

    const statusToggle = () => {
        dispatch<any>(setCNCActiveStatus(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId, marketplaceInfo.takesOrders === 1 ? 0 : 1))
    }

    return (
        <div className={styles.StatusComponent}>
            {!isAdmin && !isPharmacist && !isDriver ?
            <div className={styles.Content}>
                <span className={styles.Title}>Status apoteke</span>
                <span className={styles.PharmacyName}>{marketplaceInfo && marketplaceInfo.name}</span>
                <div className={styles.Buttons}>
                    <Button buttonType={marketplaceInfo.takesOrders === 1 ? "blue" : "red"} onClick={statusToggle}>{activeButtonTitle}</Button>
                    <Button buttonType="blue" onClick={logout}>Odjavi se</Button>
                </div>
            </div>
                :
                <div className={styles.Content}>
                    {(isAdmin || isDriver) ? <span className={styles.Title}>Admin</span> : <span className={styles.Title}>Farmaceut</span>}
                    <div className={styles.Buttons}>
                        <Button buttonType="blue" onClick={logoutAdmin}>Odjavi se</Button>
                    </div>
                </div>

            }
            <XIcon className={styles.XIcon} onClick={closeStatusScreen}/>
        </div>
    );
};

export default StatusComponent;