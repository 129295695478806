import React, {useContext, useState} from "react";
import styles from './SearchBar.module.scss';
import {FuzzySortContext} from "../../../../../../store/context/FuzzySort/FuzzySort";
import SearchItems from "../SearchItems/SearchItems";
// import {Form} from "react-bootstrap";
// import AddArticleModal from "../../../Modals/AddArticleModal/AddArticleModal";
import {useSelector} from "react-redux";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";

type Props = {
    data:any,
    noBackdrop?: boolean;
    rfzoRequest?: any,
    customClickHandler?: Function
}

const SearchBar = ({data, customClickHandler}: Props) => {

    const [searchStr, setSearchStr] = useState("");
    const [searchRes, setSearchRes] = useState(null);
    const [showSearchResults, setShowSearchResults] = useState(false);
    // const selectedItem = useSelector(state=> state.therapy.selectedItem)
    // const showModal = useSelector( state => state.therapy.showModal)

    const fuzzySort = useContext(FuzzySortContext);


    const handleChange = (e:any) => {
        setShowSearchResults(true)
        setSearchStr(e.target.value);
        if(e.target.value.length >= 3) {
            setTimeout(()=> {
                setSearchRes(e.target.value ? filterItems() : []);
            }, 600)
        } else {
            setShowSearchResults(false);
        }
        if(e.target.value === '') {
            setShowSearchResults(false);
        }
    };

    const resetSearch = () => {
        // !props.diagnosis && setShowSearchResults(false);
        setShowSearchResults(false);
        setSearchStr("");
        setSearchRes(null)
    }

    const filterItems = () => {

        const searchStringWords = searchStr.split(' ').filter(string => {
            if (string.trim() !== '') {
                return string.trim();
            }
        });


        let results = fuzzySort.filterByWords(data, searchStringWords, 0);

        results = fuzzySort.sortResultsByStartWord(results, searchStringWords[0]);

        return (results);
    }

    const backdropClickHandler = () => {
        setShowSearchResults(false);
        setSearchStr("");
    }

    const customClickHandlerWrap = (val: any) => {
        resetSearch()
        customClickHandler && customClickHandler(val)
    }

    return (
        <div style={{position:"relative", zIndex:"1"}}>
            <input
                className={styles.SearchBarInput}
                placeholder={"Pretražite artikle"}
                type="text"
                name="search-box"
                value={searchStr}
                onChange={(event:any) => handleChange(event)}
                autoComplete = "off"
            />
            {showSearchResults &&
                <>
                    <SearchItems 
                    customClickHandler={customClickHandler ? (val: any) => customClickHandler && customClickHandlerWrap(val) : undefined}
                    className={styles.SearchItems}
                                 style={!showSearchResults || searchRes === null || searchRes === " "
                                     ? {display: "none"}
                                     : {zIndex: 1, backgroundColor: "white"}} results={searchRes}
                                 close={resetSearch}
                    />
                    <Backdrop zIndex="-1" show={showSearchResults} backdropClickHandler={backdropClickHandler} backgroundColor="transparent" className={styles.SearchBarBackdrop}/>
                </>
            }

        </div>
    );
}

export default SearchBar;