import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {baseUrl} from "../axios/axios";
import axios from "./../axios/axios"
import {adminCMSActions} from "./admin-cms-slice";

export const fetchAllBranchMarketplaces = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.fetchAllBranchMarketplacesStart());
        axios.get(baseUrl + '/branch_marketplace', {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                dispatch(adminCMSActions.allBranchMarketplaces(response.data));
            })
            .catch(error => {
                console.log("error fetchAllBranchMarketplaces: ")
                dispatch(adminCMSActions.fetchAllBranchMarketplacesFailed(error));
            })
    };
};

export const fetchAllMarketplacesForBranchMarketplace = (branchMarketplaceId: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.fetchMarketplacesStart());
        axios.get(baseUrl + '/branch_marketplace/marketplace/' + branchMarketplaceId, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                console.log('marketplaces')
                console.log(response.data)
                dispatch(adminCMSActions.fetchMarketplaces(response.data));
            })
            .catch(error => {
                console.log("error fetchAllMarketplaces: " + error)
                dispatch(adminCMSActions.fetchMarketplacesFailed(error));
            })
    };
};

export const fetchArticlesFromSingleMarketplace = (branchMarketplaceId: number, marketplaceId: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.fetchArticlesFromSingleMarketplaceStart());
        axios.get(baseUrl + '/item_in_marketplace/items/' + branchMarketplaceId + '/' + marketplaceId + '/0?numOfReturns=30000', {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                console.log('srticles')
                console.log(response.data.list);
                dispatch(adminCMSActions.fetchArticlesFromSingleMarketplace(response.data.list));
            })
            .catch(error => {
                console.log("error fetchArticlesFromSingleMarketplace" + error);
                dispatch(adminCMSActions.fetchArticlesFromSingleMarketplaceFailed(error))
            })
    }
}

export const changeArticleVisibilityValue = (data: [{
    branchMarketplaceId: number,
    itemId: number,
    apotekaRs: boolean
}]): ThunkAction<void, RootState, unknown, AnyAction> => {

    return async (dispatch) => {
        dispatch(adminCMSActions.changeArticleVisibilityValueStart());
        const URL = '/item_in_marketplace/branch_marketplace'
        axios.put(URL, data, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                dispatch(adminCMSActions.changeArticleVisibilityValueSuccess())
                dispatch(adminCMSActions.updateArticleVisibilityValue(data));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(adminCMSActions.changeArticleVisibilityValueFailed())
            })
    };
};

export const changeArticleOutletValue = (data: [{
    branchMarketplaceId: number,
    marketplaceId: number,
    itemId: number,
    outletMarket: boolean
}]): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.changeArticleOutletValueStart());
        const URL = '/item_in_marketplace/'
        axios.put(URL, data, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                dispatch(adminCMSActions.changeArticleOutletValueSuccess())
                dispatch(adminCMSActions.updateArticleOutletValue(data));

            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(adminCMSActions.changeArticleOutletValueFailed())
            })
    };
};

export const fetchAllArticlesForBranchMarketplace = (branchMarketplaceId: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.fetchAllArticlesForBranchMarketplaceStart());
        axios.get(baseUrl + '/branch_marketplace/item/' + branchMarketplaceId, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                console.log(response.data);
                dispatch(adminCMSActions.fetchAllArticlesForBranchMarketplace(response.data));
            })
            .catch(error => {
                console.log("error fetchArticlesFromSingleMarketplace" + error);
                dispatch(adminCMSActions.fetchAllArticlesForBranchMarketplaceFailed(error))
            })
    }
}

export const fetchMobileAdvertisement = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.fetchMobileAdvertisementsStart());
        axios.get(baseUrl + '/advertisement/', {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                console.log('advertisement')
                console.log(response.data);
                dispatch(adminCMSActions.fetchMobileAdvertisements(response.data));
            })
            .catch(error => {
                console.log("error fetchWebAdvertisement " + error);
                dispatch(adminCMSActions.fetchMobileAdvertisementsFailed(error))
            })
    }
}

export const fetchWebAdvertisement = (): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.fetchWebAdvertisementsStart());
        axios.get(baseUrl + '/advertisement?type=web', {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                console.log('web advertisement')
                console.log(response.data);
                dispatch(adminCMSActions.fetchWebAdvertisements(response.data));
            })
            .catch(error => {
                console.log("error fetchWebAdvertisement " + error);
                dispatch(adminCMSActions.fetchWebAdvertisementsFailed(error))
            })
    }
}

export const deleteAdvertisement = (advertisementID: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(adminCMSActions.deleteAdvertisementStart());
        const URL = baseUrl + '/advertisement/' + advertisementID;
        axios.delete(URL, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                console.log('deleteAdvertisementSuccess')
                console.log(response.data)
                dispatch(adminCMSActions.deleteAdvertisementSuccess());
                dispatch(fetchWebAdvertisement())
                dispatch(fetchMobileAdvertisement())
            })
            .catch(error => {
                console.log('delete advertisement error: ' + error)
                dispatch(adminCMSActions.deleteAdvertisementFailed());
            })
    }
}

export const createNewAdvertisement = (fileObject:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch) => {
        dispatch(adminCMSActions.createNewAdvertisementStart());
        const URL = baseUrl + '/advertisement/';
        axios.post(URL, fileObject, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                // todo uraditi update niza u reduxu umesto fetcha
                // console.log(response.data)
                dispatch(adminCMSActions.createNewAdvertisementSuccess(response.data))
                if(response.data.type !== 'mobile') {
                    dispatch(fetchWebAdvertisement())
                } else {
                    dispatch(fetchMobileAdvertisement())
                }
            })
            .catch(error => {
                console.log("Error createNewAdvertisement: " + error);
                dispatch(adminCMSActions.createNewAdvertisementFailed())
            })
    };
}

export const updateAdvertisement = (fileObject:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch) => {
        dispatch(adminCMSActions.updateAdvertisementStart());
        const URL = baseUrl + '/advertisement/';
        axios.put(URL, fileObject, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                // todo uraditi update niza u reduxu umesto fetcha
                // console.log(response.data)
                dispatch(adminCMSActions.updateAdvertisementSuccess())
                if(response.data.type !== 'mobile') {
                    dispatch(fetchWebAdvertisement())
                } else {
                    dispatch(fetchMobileAdvertisement())
                }
            })
            .catch(error => {
                console.log("Error createNewAdvertisement: " + error);
                dispatch(adminCMSActions.updateAdvertisementFailed())
            })
    };
}

export const sendPushNotification = (data:any): ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch) => {
        dispatch(adminCMSActions.sendPushNotificationStart());
        const URL = baseUrl + '/advertisement/notif';
        axios.post(URL, data, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                dispatch(adminCMSActions.sendPushNotificationSuccess(response.data))
            })
            .catch(error => {
                console.log("Error createNewAdvertisement: " + error);
                dispatch(adminCMSActions.sendPushNotificationFailed())
            })
    };
}