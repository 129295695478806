import React, { useEffect, useState } from "react";
import logo from "../../../assets/Apoteka-logo-web.svg";
import { useNavigate } from "react-router-dom";

const Logo = () => {
  const navigate = useNavigate();

  const redirectToOrderProcessing = () => {
    navigate("/order-processing");
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div onClick={redirectToOrderProcessing} style={{ cursor: "pointer" }}>
      <img
        src={isMobile ? "/Logo-mobile.png" : "/LOGO-WHITE.png"}
        alt="apoteka logo"
        style={{ width: isMobile ? "48px" : "200px" }}
      />
    </div>
  );
};

export default Logo;
