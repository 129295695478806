import React, {useEffect} from 'react';
import RFZOArticlesTable from "./RFZOArticlesTable/RFZOArticlesTable";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {fetchRFZOUpcomingArticles} from "../../../store/rfzoTherapy-actions";

type Props = {
    dateFrom: string,
    dateTo: string
}
const RFZOArticles = ({dateFrom, dateTo}:Props) => {

    const dispatch = useDispatch();

    const isAdmin = useSelector((state:RootState) => state.auth.isAdmin);
    const marketplaceInfo = useSelector((state: RootState) => state.auth.marketplaceInfo);
    const rfzoUpcomingArticles = useSelector((state: RootState) => state.rfzoTherapy.rfzoUpcomingArticles);

    useEffect(() => {
        if (isAdmin || localStorage.getItem("roles")?.includes('ADMIN')) {
            dispatch<any>(fetchRFZOUpcomingArticles(dateFrom, dateTo));
        } else {
            dispatch<any>(fetchRFZOUpcomingArticles(dateFrom, dateTo, marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
        }
    }, [])

    return (
        <div>
            {rfzoUpcomingArticles.length > 0 ?
                <>
                    <div>Lista artikala koji prispevaju u RFZO terapijama za izabrani datum.</div>
                    <RFZOArticlesTable articlesData={rfzoUpcomingArticles}/>
                </>
                :
                <div>Nema rezultata za traženi datum.</div>
            }
        </div>
    );
};

export default RFZOArticles;