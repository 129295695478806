import React, {useContext, useEffect, useState} from 'react';
import {OrderSocketContext} from "../../store/context/OrderSocket";
import {RFZOTherapySocketContext} from "../../store/context/RFZOTherapySocket";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {fetchAllOrdersForAdminUser, fetchAllTransactionsForAdminUser, fetchOrders} from "../../store/order-actions";
import {fetchRFZOTherapyRequests} from "../../store/rfzoTherapy-actions";
import SocketModal from "../UI/Modals/SocketModal/SocketModal";

const SocketsConnectionsComponent = () => {

    const orderSocket = useContext(OrderSocketContext)
    const rfzoTherapySocket = useContext(RFZOTherapySocketContext);
    const dispatch = useDispatch()
    const order = useSelector((state:RootState) => state.order)
    const rfzoTherapy = useSelector((state:RootState) => state.rfzoTherapy)
    const marketplaceInfo = useSelector((state:RootState) => state.auth.marketplaceInfo);
    const isAdmin = useSelector((state:RootState) => state.auth.isAdmin);

    const [showSocketModal, showSocketModalSetter] = useState(false);
    const [isAdminState, setIsAdminState] = useState(isAdmin);
    const [countDown, setCountDown] = useState(-1)
    const [socketConnected, setSocketConnected] = useState(false)

    useEffect(() => {
        if (isAdmin || localStorage.getItem("roles")?.includes('ADMIN'))
            setIsAdminState(true)
    }, [])

    useEffect(() => {
        orderSocket.connect();
        rfzoTherapySocket.connect();

        return () => {
            orderSocket.closeConnection();
            rfzoTherapySocket.closeConnection();
        }

    }, []);

    useEffect(() => {
        if(order.orderSocketConnectionStart) {
            showSocketModalSetter(false)
        }
        else if (!order.orderSocketConnectionStart && !order.orderSocketConnected && socketConnected) {
            showSocketModalSetter(true);
            setCountDown(60);
        } else if (order.orderSocketConnected === true) {
            showSocketModalSetter(false)
            setSocketConnected(true)
        }
    }, [order.orderSocketConnected, order.orderSocketConnectionStart]);

    useEffect(()=>{
        if(rfzoTherapy.rfzoSocketConnectionStart) {
            showSocketModalSetter(false)
        }
        else if(!rfzoTherapy.rfzoSocketConnectionStart && !rfzoTherapy.rfzoSocketConnected && socketConnected) {
            showSocketModalSetter(true);
            setCountDown(60);
        } else if (rfzoTherapy.rfzoSocketConnected) {
            showSocketModalSetter(false);
            setSocketConnected(true);
        }
    }, [rfzoTherapy.rfzoSocketConnected, rfzoTherapy.rfzoSocketConnectionStart])

    useEffect(()=>{
        if(countDown <= 60 && countDown > 0) {
            const intervalId = setInterval(() => {
                setCountDown(prevState => prevState - 1)
            }, 1000);
            return () => {
                clearInterval(intervalId);
            };
        }  else if (countDown === 0) {
            connectAgain()
        }
    }, [countDown])

    const connectAgain = () => {
        if(order.orderSocketConnected === false) {
            orderSocket.connect();
        }
        if(!rfzoTherapy.rfzoSocketConnected) {
            rfzoTherapySocket.connect()
        }
        if (isAdminState) {
            dispatch<any>(fetchAllOrdersForAdminUser());
            dispatch<any>(fetchAllTransactionsForAdminUser());
        } else if (marketplaceInfo !== null) {
            dispatch<any>(fetchOrders(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
            dispatch<any>(fetchRFZOTherapyRequests(marketplaceInfo.branchMarketplaceId, marketplaceInfo.marketplaceId));
        }
    }

    return (
        <>
            {showSocketModal &&
                <SocketModal show={showSocketModal}
                             connectAgain={connectAgain}
                             countDown={countDown}/>
            }
        </>
    );
};

export default SocketsConnectionsComponent;
