import React, {useEffect, useState} from 'react';
import ColumnComponent from "../../ColumnComponent/ColumnComponent";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";
import ArchivedOrderBox from "../../ArchivedOrderBox/ArchivedOrderBox";
import OrderBox from "../../OrderBoxV2/OrderBoxV2";
import PerfectScrollbar from "react-perfect-scrollbar";

type Props = {
    isAdmin?: boolean
}
const ArchivedColumn = ({isAdmin}: Props) => {

    const orderState = useSelector((state:RootState) => state.order);
    const rfzoTherapy = useSelector((state:RootState) => state.rfzoTherapy);


    const [orders, setOrders] = useState<any>(<div>Waiting...</div>)
    const [rfzoRequest, setRfzoRequest] = useState<any>("")

    useEffect(()=>{
        if(!orderState.loadingOrders) {
            if (orderState.ordersFailed) {
                //     tODO ovde prikaz ako se desila greska prilikom ucitavanja porudzbina
            } else {
                setOrders(orderState.finishedOrders.length > 0 && orderState.finishedOrders.map((order:any, index:number) => {
                    return(
                        <OrderBox archived={true} key={index} order={order} isAdmin={isAdmin}/>
                    );
                }))
            }
        }
    }, [orderState.finishedOrders, orderState.loadingOrders])

    useEffect(()=> {
        if(!rfzoTherapy.loadingRFZOTherapies) {
            if (rfzoTherapy.rfzoTherapyRequestsFailed) {
                //todo ovde obrada greske priliko ucitavanja rfzo zahteva
            } else {
                if(rfzoTherapy.rfzoTherapyRequests.length >= 0)
                    setRfzoRequest(rfzoTherapy.rfzoTherapyRequests.map((rfzoRequest: any, index: number) => {
                        if(rfzoRequest.status !== 'REQUESTED' && rfzoRequest.status !== "PROCESSED") {
                            return (
                                <OrderBox archived={true} key={index} order={rfzoRequest} isAdmin={isAdmin}/>
                            );
                        }
                    }))
            }
        }
    }, [rfzoTherapy.rfzoTherapyRequests, rfzoTherapy.loadingRFZOTherapies] )


    const rfzoRequestRequested = rfzoTherapy.rfzoTherapyRequests.filter((rfzoRequest) => {
        return rfzoRequest.status !== "REQUESTED" && rfzoRequest.status !== "PROCESSED"
    })

    return (
        <ColumnComponent leftSideLabel="Obrađene" leftSideNumber={(orderState.finishedOrders.length + rfzoRequestRequested.length).toString()} bodyStyle={{backgroundColor:"transparent", border:"none"}} style={{flex:"4"}}>
            <PerfectScrollbar style={{padding: "4px"}}>
                {orders}
                {rfzoRequest}
            </PerfectScrollbar>
        </ColumnComponent>
    );
};

export default ArchivedColumn;
