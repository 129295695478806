import axios from "./../axios/axios"
import {AnyAction, ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "./index";
import {authActions} from "./auth-slice";
import {baseUrl} from "../axios/axios";

export const login = (credentials: { email: string | null, password: string | null }, refreshLogin = false): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {

        dispatch(authActions.loginStart())

        //Disable for deploy
        // const url = 'http://localhost:8081/auth/oauth/token'

        //Enable for deploy
        // const url = 'https://apoteka.rs:8443/authorization_server/oauth/token';

        //Enable for deploy for dev server
        const url = process.env.REACT_APP_API_URL ?  process.env.REACT_APP_API_URL + "/authorization_server/oauth/token"  : 'https://test.apoteka.rs:8443/authorization_server/oauth/token';

        const params = new URLSearchParams();
        if (refreshLogin) {
            params.append('grant_type', 'refresh_token');
            params.append('refresh_token', localStorage.getItem('refreshToken') || "");
        } else {
            params.append('grant_type', 'password');
            params.append('username', credentials.email ? credentials.email : "null");
            params.append('password', credentials.password ? credentials.password : "null");
        }
        axios.post(url, params, {
            auth: {
                username: 'apoteka_rs',
                password: 'F7Q8T97@a\'QveBX?'
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {

                let approveLogin = false;

                if (response.data.userAuthorities.includes("ADMIN")) {
                    dispatch(authActions.setIsAdmin());
                    approveLogin = true;
                } else if (response.data.userAuthorities.includes("PHARMACIST_SUPPORT_USER")) {
                    dispatch(authActions.setIsPharmacist());
                    approveLogin = true;
                } else if (response.data.userAuthorities.includes("PRODAJNO_MESTO_USER")) {
                    dispatch(authActions.setRegularUser());
                    approveLogin = true;
                } else if (response.data.userAuthorities.includes("DELIVERY_USER")) {
                    dispatch(authActions.setIsDriver());
                    approveLogin = true;
                } else {
                    dispatch(authActions.loginFailed());
                }

                // dozvoli login samo ako ima neku od navedenih rola
                if (approveLogin) {
                    const expirationDate = new Date(new Date().getTime() + response.data.expires_in * 1000);

                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);
                    localStorage.setItem('expirationDate', expirationDate.toString());
                    localStorage.setItem('id', response.data.userDetails.id);
                    localStorage.setItem('email', response.data.userDetails.username);
                    localStorage.setItem('firstName', response.data.userDetails.firstName);
                    localStorage.setItem('secondName', response.data.userDetails.secondName);
                    localStorage.setItem('roles', response.data.userAuthorities);

                    dispatch(authActions.userInfoFn({
                        id: response.data.userDetails.id,
                        username: response.data.userDetails.username,
                        firstName: response.data.userDetails.firstName,
                        secondName: response.data.userDetails.secondName,
                        roles: response.data.userAuthorities
                    }));

                    dispatch(authActions.loginSuccess(response.data.access_token));
                    !refreshLogin && dispatch(fetchMarketplaceInfoOnLogin(response.data.userDetails.id));
                    dispatch(checkLoginTimeout(response.data.expires_in));
                }
            })
            .catch(error => {
                console.log('error se desio' + error);
                if (error.response !== null && error.response !== undefined) {
                    if (!refreshLogin && error.response.data.error_description === 'Bad credentials') {
                        dispatch(authActions.loginFailed());
                    } else if (refreshLogin && error.response.data.error === "invalid_token" && error.response.data.error_description.startsWith("Invalid refresh token")) {
                        // dispatch(loginRefreshFailed());
                        dispatch(authActions.logout());
                    }
                    // else dispatch(loginServerError());
                }
            })
    }
}

export const fetchMarketplaceInfo = (user_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(authActions.fetchMarketplaceInfoStart());
        axios.get(baseUrl + '/marketplace/user/' + user_id, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                dispatch(authActions.marketplaceInfo(response.data));
                localStorage.setItem("marketplaceId", response.data.marketplaceId);
                localStorage.setItem("branchMarketplaceId", response.data.branchMarketplaceId);
                response.data.boIpAddress && localStorage.setItem("boIpAddress", response.data.boIpAddress);
            })
            .catch(error => {
                dispatch(authActions.fetchMarketplaceInfoFailed(error));
            })
    };
};

export const fetchMarketplaceInfoOnLogin = (user_id: number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        dispatch(authActions.fetchMarketplaceInfoStart());
        axios.get(baseUrl + '/marketplace/user/' + user_id, {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                dispatch(authActions.marketplaceInfo(response.data));
                dispatch(setCNCActiveStatus(response.data.branchMarketplaceId, response.data.marketplaceId, 1))
                localStorage.setItem("marketplaceId", response.data.marketplaceId);
                localStorage.setItem("branchMarketplaceId", response.data.branchMarketplaceId);
                response.data.boIpAddress && localStorage.setItem("boIpAddress", response.data.boIpAddress);
            })
            .catch(error => {
                dispatch(authActions.fetchMarketplaceInfoFailed(error));
            })
    };
};


export const logoutAction = (branchMarketplaceId:number | null, marketplaceId:number | null, activeStatus:number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        const URL = '/marketplace/takes_orders/' + branchMarketplaceId + '/' + marketplaceId + '?takesOrders=' + activeStatus;
        axios.put(URL)
            .then(response => {
                dispatch(authActions.setActiveStatus(activeStatus))
                dispatch(authActions.logout());
            })
            .catch(error => {
                console.log("Error se desio" + error);
                // dispatch(orderActions.fetchNewOrderFailed())
            })
    };
};

export const setCNCActiveStatus = (branchMarketplaceId:number, marketplaceId:number, activeStatus:number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        const URL = '/marketplace/takes_orders/' + branchMarketplaceId + '/' + marketplaceId + '?takesOrders=' + activeStatus;
        axios.put(URL)
            .then(response => {
                dispatch(authActions.setActiveStatus(activeStatus))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                // dispatch(orderActions.fetchNewOrderFailed())
            })
    };
};


export const pingActiveStatus = (branchMarketplaceId:number, marketplaceId:number): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch) => {
        const URL = '/marketplace/' + branchMarketplaceId + '/' + marketplaceId;
        axios.put(URL)
            .then(response => {

            })
            .catch(error => {
                console.log("Error se desio" + error);
                // dispatch(orderActions.fetchNewOrderFailed())
            })
    };
};

export const checkLoginTimeout = (time: number):  ThunkAction<void, RootState, unknown, AnyAction> => {
    return (dispatch) => {
        setTimeout(() => {
            if (localStorage.getItem("refreshToken") === null || localStorage.getItem("refreshToken") === "") {
                let branchMarketplaceId:any = localStorage.getItem('branchMarketplaceId');
                let marketplaceId:any = localStorage.getItem('marketplaceId');
                dispatch(logoutAction(branchMarketplaceId, marketplaceId, 0))
                dispatch(authActions.logout());
            } else {
                dispatch(login({email: null, password: null}, true));
            }
        }, time * 1000)
    }
}

export const loginCheckState = ():  ThunkAction<void, RootState, unknown, AnyAction>  => {
    return (dispatch) => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(authActions.logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate') || "");

            if (expirationDate <= new Date()) {
                localStorage.removeItem('token');
                dispatch(login({email: null, password: null}, true));
            } else {
                dispatch(authActions.loginSuccess(token));
                if (localStorage.getItem('id')) {
                    dispatch(authActions.userInfoFn(
                         {
                            id: localStorage.getItem('id')!,
                            username: localStorage.getItem('email')!,
                            firstName: localStorage.getItem('firstName')!,
                            secondName: localStorage.getItem('secondName')!,
                            roles: localStorage.getItem('roles')!.split(',')
                        }
                    ))
                }
                dispatch(checkLoginTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}