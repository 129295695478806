import React, {useEffect, useRef, useState} from "react";
import SearchItem from "./SearchItem/SearchItem";
import styles from './SearchItems.module.scss';

type Props = {
    // children?: React.ReactNode,
    // show: boolean,
    // backdropClickHandler?: React.MouseEventHandler,
    className?: string,
    style?: any,
    results?: any,
    close?: any,
    onClick?: React.MouseEventHandler
    customClickHandler?: Function
}

type Article = {
    id: number,
    name: string
}

const SearchItems = ({className, style, results, close, onClick, customClickHandler}: Props) => {

    const useKeyPress = function (targetKey:any) {

        const [keyPressed, setKeyPressed] = useState(false)

        const downHandler = ({key}:any) => {
            if(key === targetKey) {
                setKeyPressed(true)
            }
        }

        const upHandler = ({key}:any) => {
            if(key === targetKey) {
                setKeyPressed(false)
            }
        }

        useEffect(()=>{
            window.addEventListener('keydown', downHandler);
            window.addEventListener('keyup', upHandler);

            return () => {
                window.removeEventListener("keydown", downHandler);
                window.removeEventListener("keyup", upHandler);
            };
        })

        return keyPressed
    };

    const [selected, setSelected] = useState<Article>();
    const downPress = useKeyPress("ArrowDown");
    const upPress = useKeyPress("ArrowUp");
    const enterPress = useKeyPress("Enter");
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState();
    const [items, setItems] = useState<Article[]>([])

    const fieldRef = useRef<null | HTMLDivElement>(null)

    useEffect(() => {
        if (items) {
            setItems([]);

            results && results.forEach((res: Article) => {
                setItems((prev) => [...prev, res]);
            });
        }
    }, [results]);

    useEffect(()=>{
        if (cursor && fieldRef) {
            fieldRef.current?.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
                inline: 'start'
            });
        }
    }, [cursor])

    const ListItem = ({ item, active, customClickHandler }:any) => (
        <div
            className={`${active ? styles.ItemActive : ""}`}
            ref={active ? fieldRef : null}
        >
            <SearchItem customClickHandler={customClickHandler} item={item} close={close} enter={selected}/>
        </div>
    );

    useEffect(() => {
        if (items && items.length && downPress) {
            setCursor(prevState =>
                prevState < items.length - 1 ? prevState + 1 : prevState
            );
        }
    }, [downPress]);

    useEffect(() => {
        if (items && items.length && upPress) {
            setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
        }
    }, [upPress]);

    useEffect(() => {
        if (items && items.length && enterPress) {
            setSelected(items[cursor]);
        }
    }, [cursor, enterPress]);

    useEffect(() => {
        if (items && items.length && hovered) {
            setCursor(items.indexOf(hovered));
        }
    }, [hovered]);


    return (
        <div className={`${styles.SearchItems} ${className}`} style={style} onClick={onClick}>
            {items && items.map((item, i) => {
                return (
                    <ListItem
                        customClickHandler={customClickHandler}
                        key={i}
                        active={i === cursor}
                        item={item}
                        setSelected={setSelected}
                        setHovered={setHovered}
                    />
                )
            })
            }
            {/*{results &&*/}
            {/*    results.map((res:any) => (*/}
            {/*        <SearchItem key={res.id} item={res} close={close}/>*/}
            {/*    ))}*/}
        </div>
    );
}

export default SearchItems;