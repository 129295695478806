import React, {CSSProperties, useEffect} from 'react';
import {fetchArticlesFromSingleMarketplace} from "../../../store/admin-cms-actions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import ArticlesTableForSingleMarketplace from "./ArticleTableForSingleMarketplace/ArticlesTableForSingleMarketplace";
import {ScaleLoader} from "react-spinners";

type Props = {
    marketplaceId: number,
    branchMarketplaceId: number,
}
const ArticlesForSingleMarketplace = ({branchMarketplaceId, marketplaceId}: Props) => {

    const dispatch = useDispatch();

    const articlesFromSingleMarketplaceArray = useSelector((state: RootState) => state.adminCMS.articleFromSingleMarketplaceArray);
    const loadingArticles = useSelector((state:RootState) => state.adminCMS.fetchArticlesFromSingleMarketplaceStart);
    const loadingArticlesFailed = useSelector((state: RootState) => state.adminCMS.fetchArticlesFromSingleMarketplaceFailed);

    useEffect(() => {
        if (marketplaceId !== -1 && branchMarketplaceId !== -1)
            dispatch<any>(fetchArticlesFromSingleMarketplace(branchMarketplaceId, marketplaceId))
    }, [marketplaceId]);

    const override: CSSProperties = {
        display: "flex",
        justifyContent:"center",
        alignItems:"center",
        height:"20vh",
    };

    return (
        <div style={{position:"relative"}}>
            {loadingArticles &&
                <ScaleLoader
                    color={"#00B2B1"}
                    loading={loadingArticles}
                    cssOverride={override}
                    width={10}
                    radius={5}
                    height={50}
                    aria-label="Učitavanje"
                    data-testid="loader"
                />}
            {articlesFromSingleMarketplaceArray.length > 0 && !loadingArticles &&
                <ArticlesTableForSingleMarketplace articlesData={articlesFromSingleMarketplaceArray} branchMarketplaceId={branchMarketplaceId} marketplaceId={marketplaceId}/>
            }
            {loadingArticlesFailed && !loadingArticlesFailed &&
                <div>Greška pri učitavanju podataka.</div>
            }
        </div>
    );
};

export default ArticlesForSingleMarketplace;