import React from 'react';
import styles from './SocketModal.module.scss'
import Button from "../../Buttons/Button/Button";
import Backdrop from "../../Backdrop/Backdrop";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";



type Props = {
    show: boolean;
    connectAgain: React.MouseEventHandler,
    countDown: number

}
const SocketModal = ({show, connectAgain, countDown}:Props) => {

    const orderSocket = useSelector((state:RootState) => state.order)
    const rfzoTherapySocket = useSelector((state:RootState) => state.rfzoTherapy)

    return (
        <>
        <div className={styles.SocketModal}>
            <div className={styles.SocketModalHeader}>
                <span>Web Socket povezivanje</span>
            </div>
            <div className={styles.SocketModalContent}>
                <div className={styles.ConfirmationModalMessage}>
                    {(!orderSocket.orderSocketConnected || !rfzoTherapySocket.rfzoSocketConnected) &&
                        <>
                            <div className={styles.SocketModalMessage}>Došlo je do greške prilikom povezivanja.</div>
                            <div className={styles.SocketModalMessage}>Nakon {countDown} sekundi sistem će automatski
                                pokušati da obnovi vezu.
                            </div>
                            <div className={styles.SocketModalMessage}>Da biste ubrzali proces, kliknite na dugme
                                "Poveži se ponovo".
                            </div>
                        </>
                    }

                </div>
                <Button buttonType="blue"
                        onClick={connectAgain}
                        style={{marginTop:"auto"}}
                >Poveži se ponovo</Button>
            </div>

        </div>

    {show &&
    <Backdrop show={show}
              zIndex={40}
              backgroundColor="rgba(0, 0, 0, .2)"/>
    }

</>
    );
};

export default SocketModal;