import React from 'react';
import styles from './FooterComponent.module.css'

const FooterComponent = () => {
    return (
        <div className={styles.FooterWrapper}>
            <div className={styles.Footer}>
                Copyright &copy; 2024 apoteka
            </div>
        </div>
    );
};

export default FooterComponent;
