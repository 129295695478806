import React, {useEffect} from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import Button from "../Buttons/Button/Button";
import styles from './ImageUploader.module.scss'
import {useDispatch} from "react-redux";
import {adminCMSActions} from "../../../store/admin-cms-slice";

const ImageUploader = () => {

    const dispatch = useDispatch();
    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const onChange = (
        imageList: ImageListType,
        // addUpdateIndex: number[] | undefined
    ) => {
        // data for submit
        // console.log(imageList, addUpdateIndex);
        setImages(imageList as never[]);
    };

    useEffect(()=>{
        if(images.length !== 0) {
            dispatch(adminCMSActions.uploadedImageData(images))
        }
    }, [images])

    return (
        <div className={styles.UploadImageLib}>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps
                  }) => (
                    // write your building UI
                    <div className={styles.UploadImageWrapper}>
                        {imageList.length === 0 &&
                        <div className={styles.ImageUploadField}
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            <div>Kliknite ovde da biste izabrali sliku</div>
                            <div>ili je prevucite u polje.</div>
                        </div>
                        }
                        {imageList.map((image, index) => (
                            <div key={index} className={styles.UpladedImage}>
                                <img src={image.dataURL} alt="" width="100%" />
                                <div className="image-item__btn-wrapper">
                                    <Button buttonType="blue" onClick={() => onImageUpdate(index)}>Promeni sliku</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </ImageUploading>
        </div>
    );
}
export default ImageUploader;
