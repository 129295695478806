import React from "react";
import styles from "./Tabs.module.scss";
import classNames from "classnames";

type Tab = {
  label: string;
  count: string | number;
};

type Props = {
  tabsList: Tab[];
  selected: number;
  onChange: (tab: any) => any;
};

function Tabs({ tabsList, onChange, selected }: Props) {
  return (
    <div className={styles.tabsList}>
      {tabsList.map((tab, index) => (
        <div
          key={index}
          className={classNames(styles.tab, {
            [styles.selected]: selected == index,
          })}
          onClick={() => onChange(index)}>
          <div>{tab.label}</div>

          <div className={styles.tabCount}>{tab.count}</div>
        </div>
      ))}
    </div>
  );
}

export default Tabs;
