import {createSlice} from "@reduxjs/toolkit";

type branchMarketplaceType = {
    id: number;
    name: string;
}

type branchMarketplaceTypeForSelect = {
    value: string;
    label: string;
}

type marketplaceType = {
    id: number;
    name: string;
    address: string;
}

type marketplaceTypeForSelectType = {
    value: string;
    label: string;
}

type image = {
    dataURL: string;
    file: File;
}

export interface AdminCMSState {
    branchMarketplaceArray: branchMarketplaceType[],
    branchMarketplaceArrayForSelect: branchMarketplaceTypeForSelect[],
    fetchAllBranchMarketplacesStart: boolean,
    fetchAllBranchMarketplacesFailed: boolean,

    marketplaceArray: marketplaceType[],
    marketplaceArrayForSelect: marketplaceTypeForSelectType[],
    fetchMarketplacesStart: boolean,
    fetchMarketplacesFailed: boolean,

    articleFromSingleMarketplaceArray:[],
    fetchArticlesFromSingleMarketplaceStart: boolean,
    fetchArticlesFromSingleMarketplaceFailed: boolean,

    changeArticleOutletValueStart: boolean,
    changeArticleOutletValueSuccess: boolean,
    changeArticleOutletValueFailed: boolean,

    changeArticleVisibilityValueStart: boolean,
    changeArticleVisibilityValueSuccess: boolean,
    changeArticleVisibilityValueFailed: boolean,

    fetchAllArticlesForBranchMarketplaceStart: boolean,
    allArticlesForSingleBranchMarketplace: [],
    fetchAllArticlesForBranchMarketplaceFailed: boolean

    fetchMobileAdvertisementsStart: boolean,
    mobileAdvertisements: any[],
    fetchMobileAdvertisementsFailed: boolean

    fetchWebAdvertisementsStart: boolean,
    webAdvertisements: any[],
    fetchWebAdvertisementsFailed: boolean,

    deleteAdvertisementStart: boolean,
    deleteAdvertisementSuccess: boolean,
    deleteAdvertisementFailed: boolean,

    uploadedImageData: image[],

    createNewAdvertisementStart: boolean,
    createNewAdvertisementSuccess: boolean,
    createNewAdvertisementFailed: boolean,

    updateAdvertisementStart: boolean,
    updateAdvertisementSuccess: boolean,
    updateAdvertisementFailed: boolean,

    pushNotificationStart: boolean,
    pushNotificationSuccess: boolean,
    pushNotificationFailed: boolean,
}

const initialState:AdminCMSState = {
    branchMarketplaceArray: [],
    branchMarketplaceArrayForSelect:[],
    fetchAllBranchMarketplacesStart: false,
    fetchAllBranchMarketplacesFailed: false,

    marketplaceArray: [],
    marketplaceArrayForSelect: [],
    fetchMarketplacesStart: false,
    fetchMarketplacesFailed: false,

    articleFromSingleMarketplaceArray: [],
    fetchArticlesFromSingleMarketplaceStart: false,
    fetchArticlesFromSingleMarketplaceFailed: false,

    changeArticleOutletValueStart: false,
    changeArticleOutletValueSuccess: false,
    changeArticleOutletValueFailed: false,

    changeArticleVisibilityValueStart: false,
    changeArticleVisibilityValueSuccess: false,
    changeArticleVisibilityValueFailed: false,

    fetchAllArticlesForBranchMarketplaceStart: false,
    allArticlesForSingleBranchMarketplace: [],
    fetchAllArticlesForBranchMarketplaceFailed: false,

    fetchMobileAdvertisementsStart: false,
    mobileAdvertisements: [],
    fetchMobileAdvertisementsFailed: false,

    fetchWebAdvertisementsStart: false,
    webAdvertisements: [],
    fetchWebAdvertisementsFailed: false,

    deleteAdvertisementStart: false,
    deleteAdvertisementSuccess: false,
    deleteAdvertisementFailed: false,

    uploadedImageData: [],

    createNewAdvertisementStart: false,
    createNewAdvertisementSuccess: false,
    createNewAdvertisementFailed: false,

    updateAdvertisementStart: false,
    updateAdvertisementSuccess: false,
    updateAdvertisementFailed: false,

    pushNotificationStart: false,
    pushNotificationSuccess: false,
    pushNotificationFailed: false,
}

const adminCMSSlice = createSlice({
    name: "adminCMS",
    initialState: initialState,
    reducers: {
        fetchAllBranchMarketplacesStart: (state) => {
            state.fetchAllBranchMarketplacesStart = true;
        },
        allBranchMarketplaces: (state, action) => {
            state.fetchAllBranchMarketplacesStart = false;
            state.fetchAllBranchMarketplacesFailed = false;
            action.payload.map((branchMarketplace:any) => {
                state.branchMarketplaceArray.push({
                    id: branchMarketplace.id,
                    name: branchMarketplace.name
                })
                state.branchMarketplaceArrayForSelect.push({
                    value: branchMarketplace.id.toString(),
                    label: branchMarketplace.name
                })
            });
        },
        fetchAllBranchMarketplacesFailed: (state) => {
            state.fetchAllBranchMarketplacesStart = false;
            state.fetchAllBranchMarketplacesFailed = true;
        },
        fetchMarketplaces: (state, action) => {
            state.marketplaceArray = [];
            state.marketplaceArrayForSelect = [];
            state.fetchMarketplacesStart = false;
            state.fetchMarketplacesFailed = false;
            action.payload.map((marketplace:any) => {
                state.marketplaceArray.push({
                    id: marketplace.marketplaceId,
                    name: marketplace.name,
                    address: marketplace.address
                })
                state.marketplaceArrayForSelect.push({
                    value: marketplace.marketplaceId.toString(),
                    label: marketplace.name + ', ' + marketplace.address,
                })

            })
        },
        fetchMarketplacesStart: (state) => {
            state.fetchMarketplacesStart = true;
            state.fetchMarketplacesFailed = false;

        },
        fetchMarketplacesFailed: (state) => {
            state.fetchMarketplacesStart = false;
            state.fetchMarketplacesFailed = true;
        },
        fetchArticlesFromSingleMarketplace: (state, action) => {
            state.fetchArticlesFromSingleMarketplaceStart = false;
            state.fetchArticlesFromSingleMarketplaceFailed = false;
            state.articleFromSingleMarketplaceArray = action.payload;
        },
        fetchArticlesFromSingleMarketplaceStart: (state) => {
            state.fetchArticlesFromSingleMarketplaceStart = true;
            state.fetchArticlesFromSingleMarketplaceFailed = false;
        },
        fetchArticlesFromSingleMarketplaceFailed: (state) => {
            state.fetchArticlesFromSingleMarketplaceStart = false;
            state.fetchArticlesFromSingleMarketplaceFailed = true;
        },
        clearArticlesFromSingleMarketplace: (state) => {
            state.articleFromSingleMarketplaceArray = []
        },
        changeArticleOutletValueStart: (state) => {
            state.changeArticleOutletValueStart = true;
            state.changeArticleOutletValueSuccess = false;
            state.changeArticleOutletValueFailed = false;
        },
        changeArticleOutletValueSuccess: (state) => {
            state.changeArticleOutletValueStart = false;
            state.changeArticleOutletValueSuccess = true;
            state.changeArticleOutletValueFailed = false;
        },
        updateArticleOutletValue: (state, action) => {
            const index = state.articleFromSingleMarketplaceArray.findIndex((article:any) => {
                return article.itemResponse.id === action.payload[0].itemId
            })
            // @ts-ignore
            state.articleFromSingleMarketplaceArray[index].outletMarket = action.payload[0].outletMarket
        },
        changeArticleOutletValueFailed: (state) => {
            state.changeArticleOutletValueStart = false;
            state.changeArticleOutletValueSuccess = false;
            state.changeArticleOutletValueFailed = true;
        },
        fetchAllArticlesForBranchMarketplaceStart: (state) => {
            state.fetchAllArticlesForBranchMarketplaceStart = true;
            state.fetchAllBranchMarketplacesFailed = false;
        },
        fetchAllArticlesForBranchMarketplace: (state, action) => {
            state.fetchAllArticlesForBranchMarketplaceStart = false;
            state.allArticlesForSingleBranchMarketplace = action.payload;
            state.fetchAllArticlesForBranchMarketplaceFailed = false;
        },
        fetchAllArticlesForBranchMarketplaceFailed: (state) => {
            state.fetchAllArticlesForBranchMarketplaceStart = false;
            state.fetchAllBranchMarketplacesFailed = true;
        },
        changeArticleVisibilityValueStart: (state) => {
            state.changeArticleVisibilityValueStart = true;
            state.changeArticleVisibilityValueSuccess = false;
            state.changeArticleVisibilityValueFailed = false;
        },
        changeArticleVisibilityValueSuccess: (state) => {
            state.changeArticleVisibilityValueStart = false;
            state.changeArticleVisibilityValueSuccess = true;
            state.changeArticleVisibilityValueFailed = false;
        },
        changeArticleVisibilityValueFailed: (state) => {
            state.changeArticleVisibilityValueStart = false;
            state.changeArticleVisibilityValueSuccess = false;
            state.changeArticleVisibilityValueFailed = true;
        },
        updateArticleVisibilityValue: (state, action) => {
            const index = state.allArticlesForSingleBranchMarketplace.findIndex((article:any) => {
                return article.itemResponse.id === action.payload[0].itemId
            })
            // @ts-ignore
            state.allArticlesForSingleBranchMarketplace[index].apotekaRs = action.payload[0].apotekaRs
        },
        fetchMobileAdvertisementsStart:(state) => {
            state.fetchMobileAdvertisementsStart = true;
            state.fetchMobileAdvertisementsFailed = false;
        },
        fetchMobileAdvertisements:(state, action) => {
            state.mobileAdvertisements = []
            state.fetchMobileAdvertisementsStart = false;
            state.fetchMobileAdvertisementsFailed = false;
            action.payload.map((advertisement:any) => {
                state.mobileAdvertisements.push(advertisement)
            })
        },
        fetchMobileAdvertisementsFailed: (state) => {
            state.fetchMobileAdvertisementsStart = false;
            state.fetchMobileAdvertisementsFailed = true;
        },
        fetchWebAdvertisementsStart:(state) => {
            state.fetchWebAdvertisementsStart = true;
            state.fetchWebAdvertisementsFailed = false;
        },
        fetchWebAdvertisements:(state, action) => {
            state.webAdvertisements = []
            state.fetchWebAdvertisementsStart = false;
            state.fetchWebAdvertisementsFailed = false;
            action.payload.map((advertisement:any) => {
                state.webAdvertisements.push(advertisement)
            })
        },
        fetchWebAdvertisementsFailed: (state) => {
            state.fetchWebAdvertisementsStart = false;
            state.fetchWebAdvertisementsFailed = true;
        },
        deleteAdvertisementStart: (state) => {
            state.deleteAdvertisementStart = true;
            state.deleteAdvertisementSuccess = false;
            state.deleteAdvertisementFailed = false;
        },
        deleteAdvertisementSuccess: (state) => {
            state.deleteAdvertisementStart = false;
            state.deleteAdvertisementSuccess = true;
            state.deleteAdvertisementFailed = false;
        },
        deleteAdvertisementFailed: (state) => {
            state.deleteAdvertisementStart = false;
            state.deleteAdvertisementSuccess = false;
            state.deleteAdvertisementFailed = true;
        },
        uploadedImageData: (state, action) => {
            state.uploadedImageData = action.payload;
        },
        createNewAdvertisementStart: (state) => {
            state.createNewAdvertisementStart = true;
            state.createNewAdvertisementSuccess = false;
            state.createNewAdvertisementFailed = false
        },
        createNewAdvertisementSuccess: (state) => {
            state.createNewAdvertisementStart = false;
            state.createNewAdvertisementSuccess = true;
            state.createNewAdvertisementFailed = false
        },
        createNewAdvertisementFailed: (state) => {
            state.createNewAdvertisementStart = false;
            state.createNewAdvertisementSuccess = false;
            state.createNewAdvertisementFailed = true;
        },
        resetCreateNewAdvertisement: (state) => {
            state.createNewAdvertisementStart = false;
            state.createNewAdvertisementSuccess = false;
            state.createNewAdvertisementFailed = false;
        },
        resetUploadedImageData: (state) => {
            state.uploadedImageData = [];
        },
        updateAdvertisementStart: (state) => {
            state.updateAdvertisementStart = true;
            state.updateAdvertisementSuccess = false;
            state.updateAdvertisementFailed = false;
        },
        updateAdvertisementSuccess: (state) => {
            state.updateAdvertisementStart = false;
            state.updateAdvertisementSuccess = true;
            state.createNewAdvertisementFailed = false;
        },
        updateAdvertisementFailed: (state) => {
            state.updateAdvertisementStart = false;
            state.updateAdvertisementSuccess = false;
            state.updateAdvertisementFailed = true;
        },
        resetUpdateAdvertisement: (state) => {
            state.updateAdvertisementStart = false;
            state.updateAdvertisementSuccess = false;
            state.createNewAdvertisementFailed = false;
        },
        sendPushNotificationStart:(state) => {
            state.pushNotificationStart = true;
            state.pushNotificationSuccess = false;
            state.pushNotificationFailed = false;
        },
        sendPushNotificationSuccess: (state) => {
            state.pushNotificationStart = false;
            state.pushNotificationSuccess = true;
            state.pushNotificationFailed = false;
        },
        sendPushNotificationFailed: (state) => {
            state.pushNotificationStart = false;
            state.pushNotificationSuccess = false;
            state.pushNotificationFailed = true;
        }
    }
});

export const adminCMSActions = adminCMSSlice.actions;

export default adminCMSSlice;

// Ovaj POST vam vraca AdvertisementResponse sa svim informacijama o ovoj reklami
