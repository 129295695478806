import React from "react";
import styles from "./ArticleItem.module.scss";
import { ReactComponent as XIcon } from "./../../../../../assets/icons/trashIcon.svg";

type Props = {
  article: any;
  xIcon?: boolean;
  removeArticle: any;
};

const ArticleItem = ({ article, xIcon, removeArticle }: Props) => {
  return (
    <div>
      <div className={styles.ArticleItem}>
        <span className={styles.ArticleName}>{article.articleName}</span>
        <div className={styles.ArticleData}>
          <span>{article.rfzoQuantity} x</span>
          <span>{article?.rfzoParticipationPrice?.toFixed(2)} RSD</span>
          {xIcon && (
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                removeArticle(article);
              }}>
              <XIcon className={styles.TrashIcon} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleItem;
