import React from 'react';
import Logo from "../../components/UI/Logo/Logo"
import styles from './AuthPage.module.scss';
import LoginPane from "../../components/AuthComponents/LoginPane/LoginPane";

const AuthPage = () => {
    return (
        <div className={styles.AuthPageContent}>
            <div className={styles.ContentWrapper}>
                <Logo/>
                <LoginPane/>
            </div>
        </div>
    );
};

export default AuthPage;
