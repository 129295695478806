import React, {useMemo} from 'react';
// @ts-ignore
import {useSortBy, useTable} from "react-table";
import styles from "../../../ArticlesComponents/ArticlesTable.module.scss";

type Props = {
    usersData: any[]
}

const RFZOPatientsTable = ({usersData}: Props) => {

    const data = useMemo(() => {
        return usersData.map((user: any) => {
            return (
                {
                    col1: user?.userFirstName + " " + user?.userSecondName,
                    col2: user?.userPhone || user?.userResponse?.phoneNumber,
                    col3: user.items.length,
                    col4: user.willBeReadyFrom,
                    col5: user.willBeReadyTo,
                    col6: user.userResponse ? user.userResponse.username : user.userEmail,
                    col7: user.userLBO,
                    col8: user.status
                }
            )
        })
    },[usersData])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Ime i prezime pacijenta',
                accessor: 'col1', // accessor is the "key" in the data
            },
            {
                Header: 'Broj telefona',
                accessor: 'col2',
            },
            {
                Header: 'Broj artikala',
                accessor: 'col3',
            },
            {
                Header: 'Terapija dostupna od',
                accessor: 'col4'
            },
            {
                Header: 'Terapija dostupna do',
                accessor: 'col5'
            },
            {
                Header: 'Email',
                accessor: 'col6'
            },
            {
                Header: 'LBO',
                accessor: 'col7'
            },
            {
                Header: 'Status',
                accessor: 'col8'
            }
        ],
        []
    );

    const tableInstance = useTable({columns, data}, useSortBy)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance

    return (
        <table {...getTableProps()} style={{border: 'solid 1px blue'}}>
            <thead>
            {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            <div style={{display:"flex", justifyContent:"center"}}>{column.render('Header')}
                                <span>
                                    {column.isSorted
                                    ? (column.isSortedDesc
                                          ? ' ▼'
                                           : ' ▲')
                                     : ''}
                                </span>
                            </div>
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()} className={styles.Tr}>
                        {row.cells.map((cell: any) => {
                            return (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
};

export default RFZOPatientsTable;