import {createSlice} from "@reduxjs/toolkit";

export interface PatientsState {
    allPatientsStart: boolean,
    allPatients: any[],
    allPatientsFailed: boolean
}

const initialState: PatientsState = {
    allPatientsStart: false,
    allPatients: [],
    allPatientsFailed: false
}

const patientsSlice = createSlice({
    name: "patients",
    initialState: initialState,
    reducers: {
        fetchAllPatientsStart: (state) => {
            state.allPatientsStart = true;
            state.allPatients = [];
            state.allPatientsFailed = false
        },
        fetchAllPatientsSuccess: (state, action) => {
            state.allPatientsStart = false;
            state.allPatients = action.payload;
            state.allPatientsFailed = false
        },
        fetchAllPatientsFailed: (state) => {
            state.allPatientsStart = false;
            state.allPatients = [];
            state.allPatientsFailed = true
        }
    }
});

export const patientsActions = patientsSlice.actions;


export default patientsSlice;