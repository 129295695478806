import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {fetchMobileAdvertisement} from "../../store/admin-cms-actions";
import SingleCommercial from "./SingleCommercial/SingleCommercial";
import styles from './Commercials.module.scss'
import Button from "../UI/Buttons/Button/Button";
import NewCommercial from "./NewCommercial/NewCommercial";
import NotificationMessage from "../UI/NotificationMessage/NotificationMessage";

const MobileCommercials = () => {

    const dispatch = useDispatch();
    const mobileCommercials = useSelector((state: RootState) => state.adminCMS.mobileAdvertisements)
    const loadingMobileCommercials = useSelector((state: RootState) => state.adminCMS.fetchMobileAdvertisementsStart)
    const createNewAdvertisementSuccess = useSelector((state:RootState) => state.adminCMS.createNewAdvertisementSuccess);
    const updateAdvertisementSuccess = useSelector((state:RootState) => state.adminCMS.updateAdvertisementSuccess);

    const [newAdvModalVisible, setNewAdvModalVisible] = useState(false);
    const [notificationMessageVisible, setNotificationMessageVisible] = useState(false);
    const [updateNotificationMessageVisible, setUpdateNotificationMessageVisible] = useState(false);

    useEffect(() => {
        if (mobileCommercials.length === 0) {
            dispatch<any>(fetchMobileAdvertisement())
        }
    }, [])

    useEffect(() => {
        if(updateAdvertisementSuccess) {
            setUpdateNotificationMessageVisible(true)
            setTimeout(()=> {
                setUpdateNotificationMessageVisible(false)
            }, 5000)
        }
    }, [updateAdvertisementSuccess])

    useEffect(() => {
        if(createNewAdvertisementSuccess) {
            setNotificationMessageVisible(true)
            setTimeout(()=> {
                setNotificationMessageVisible(false)
            }, 5000)
        }
    }, [createNewAdvertisementSuccess])

    const createNewCommercial = () => {
        setNewAdvModalVisible(true)
    }

    const closeNewCommercialModal = () => {
        setNewAdvModalVisible(false)
    }

    return (
        <>
            <div className={styles.CommercialPageHeader}>
                <span style={{fontSize: "24px"}}>Podešavanje reklama za mobile</span>
                <Button buttonType='blue' style={{width: '200px'}} onClick={createNewCommercial}>Dodaj reklamu</Button>
            </div>
            <div className={styles.CommercialImages}>
                {mobileCommercials.length > 0 && !loadingMobileCommercials &&
                    mobileCommercials.map((commercial: any, index: number) => {
                        return (
                            <SingleCommercial className={styles.SingleCommercialMobile} key={index}
                                              commercial={commercial} mobile/>
                        )
                    })}
            </div>

            {newAdvModalVisible &&
                <NewCommercial mobile show={newAdvModalVisible} closeNewCommercialModal={closeNewCommercialModal}/>
            }

            {notificationMessageVisible &&
                <NotificationMessage onClick={() => setNotificationMessageVisible(false)}>
                    Uspešno ste dodali reklamu!
                </NotificationMessage>
            }
            {updateNotificationMessageVisible &&
                <NotificationMessage onClick={() => setUpdateNotificationMessageVisible(false)}>
                    Uspešno ste ažurirali reklamu!
                </NotificationMessage>
            }
        </>

    );
};

export default MobileCommercials;